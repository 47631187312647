<template>
    <div id="ask-top-number">
        <div v-if="defaultTop" class="atn-left"  v-loading="loading">
            <div class="atn-left-t">
                <span class="atn-left-t-icon">
                    <i class="iconfont guoran-tongyichicun-renshu"></i>
                </span>
                <span class="atn-left-t-num">
                    {{ receptionData.totalNum}}
                    <span class="atn-right-cell-t-l-uni-right">{{$t('statisticsUp.topNumber.effective')}}<span class="arctlur-num-blue">{{receptionData.validRecordNum == null?'0':receptionData.validRecordNum}}</span></span>
                    <span class="atn-right-cell-t-l-uni-right"> / </span>
                    <span class="atn-right-cell-t-l-uni-right">{{$t('statisticsUp.topNumber.invalid')}}<span class="arctlur-num-orange">{{receptionData.invalidRecordNum == null?'0':receptionData.invalidRecordNum}}</span></span>
                </span>
                <div class="atn-left-t-msg top-comp">
                    <span class="altm-icon">
                        <el-tooltip class="item" effect="dark" placement="top-start">
                            <div slot="content">
                                <div>{{$t('statisticsUp.topNumber.topTitle1')}}</div>
                                <div>{{$t('statisticsUp.topNumber.topTitle3')}}</div>
                                <div>{{$t('statisticsUp.topNumber.topTitle4')}}</div>
                                <div>{{$t('statisticsUp.topNumber.topTitle5')}}</div>
                                <!-- <div>{{$t('statisticsUp.topNumber.topTitle2')}}</div> -->
                            </div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <span class="altm-name">{{$t('statisticsUp.totalNumberOfReceptions')}}</span>
                    <span class="altm-type">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="receptionData"></ration-text>
                </div>
            </div>
            <div style="height: 96px" class="atn-left-b" id="echar-atn-left-b"></div>
        </div>
        <div v-else class="atn-right-pre"  v-loading="loading">
            <div class="atn-left-t-pre">
                <span class="atn-left-t-num">
                    {{ receptionData.totalNum}}
                    <span class="atn-right-cell-t-l-uni-right">{{$t('statisticsUp.topNumber.effective')}}<span class="arctlur-num-blue">{{receptionData.validRecordNum == null?'0':receptionData.validRecordNum}}</span></span>
                    <span class="atn-right-cell-t-l-uni-right"> / </span>
                    <span class="atn-right-cell-t-l-uni-right">{{$t('statisticsUp.topNumber.invalid')}}<span class="arctlur-num-orange">{{receptionData.invalidRecordNum == null?'0':receptionData.invalidRecordNum}}</span></span>
                </span>
                <div class="atn-left-t-msg top-comp">
                    <span class="altm-icon">
                        <el-tooltip class="item" effect="dark" placement="top-start">
                            <div slot="content">
                                <div>{{$t('statisticsUp.topNumber.topTitle1')}}</div>
                                <div>{{$t('statisticsUp.topNumber.topTitle3')}}</div>
                                <div>{{$t('statisticsUp.topNumber.topTitle4')}}</div>
                                <div>{{$t('statisticsUp.topNumber.topTitle5')}}</div>
                                <!-- <div>{{$t('statisticsUp.topNumber.topTitle2')}}</div> -->
                            </div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <span class="altm-name">{{$t('statisticsUp.totalNumberOfReceptions')}}</span>
                    <span class="altm-type">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="receptionData"></ration-text>
                </div>
            </div>
            <div class="atn-left-t-pre" style="margin-top: 14px">
                <span class="atn-left-t-num">
                    {{ solutionData.botSolveRate}}
                </span>
                <div class="atn-left-t-msg top-comp">
                    <span class="altm-icon">
                        <el-tooltip class="item" effect="dark" placement="top-start">
                            <div slot="content">
                                <div>{{$t('statisticsUp.botSolveRate.topTitle1')}}</div>
                                <div>{{$t('statisticsUp.botSolveRate.topTitle2')}}</div>
                                <div>{{$t('statisticsUp.botSolveRate.topTitle3')}}</div>
                            </div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <span class="altm-name">{{$t('statisticsUp.robotResolutionRate')}}</span>
                    <span class="altm-type">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="solutionData"></ration-text>
                </div>
            </div>
        </div>
        <div class="atn-right">
            <div class="atn-right-cell">
                <div class="atn-right-cell-t">
                    <div class="atn-right-cell-t-l">
                        <span class="atn-right-cell-t-l-num">{{sessionData.totalNum}}</span>
                    </div>
                    <div class="atn-right-cell-t-r">
                        <span class="atn-left-t-icon b-color-lg">
                            <i class="iconfont guoran-tongyichicun-dangqianhuihuashu"></i>
                        </span>
                    </div>
                </div>
                <div class="top-comp atn-right-cell-b">
                    <span class="altm-icon">
                        <el-tooltip class="item" effect="dark" placement="top-start">
                            <div slot="content">
                                <div>{{$t('statisticsUp.topNumber.tip1')}}</div>
                                <div>{{$t('statisticsUp.topNumber.tip2')}}</div>
                                <div>{{$t('statisticsUp.topNumber.tip3')}}</div>
                            </div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <span class="altm-name">{{$t('statisticsUp.sessionsNumber')}}</span>
                    <span class="altm-type">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="sessionData"></ration-text>
                </div>
            </div>
            <div class="atn-right-cell">
                <div class="atn-right-cell-t">
                    <div class="atn-right-cell-t-l">
                        <span class="atn-right-cell-t-l-num">{{messageData.totalNum}}</span>
                        <span class="atn-right-cell-t-l-uni">{{$t('statisticsUp.number')}}</span>
                        <span class="atn-right-cell-t-l-uni-right">/{{$t('statisticsUp.unrecognized')}} 
                            <span class="arctlur-num-orange">{{messageData.noIdentifiedNum}} </span>
                            {{$t('statisticsUp.number')}}
                        </span>
                    </div>
                    <div class="atn-right-cell-t-r">
                        <span class="atn-left-t-icon b-color-b">
                            <i class="iconfont guoran-tongyichicun-duihuajilu"></i>
                        </span>
                    </div>
                </div>
                <div class="top-comp atn-right-cell-b">
                    <span class="altm-icon">
                        <el-tooltip class="item" effect="dark" placement="top-start">
                            <div slot="content">
                                <div>{{$t('statisticsUp.topNumber.sendQuestion')}}</div>
                            </div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <span class="altm-name">{{$t('statisticsUp.questionNumber')}}</span>
                    <span class="altm-type">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="messageData"></ration-text>
                </div>
            </div>
            <div class="atn-right-cell">
                <div class="atn-right-cell-t">
                    <div class="atn-right-cell-t-l">
                        <span class="atn-right-cell-t-l-num">{{firstResponseTimeData.respTime || 0}}</span>
                        <span class="atn-right-cell-t-l-uni">S</span>
                    </div>
                    <div class="atn-right-cell-t-r">
                        <span class="atn-left-t-icon b-color-g">
                            <i class="iconfont guoran-tongyichicun-jiqirenshoucipingjunxiangyingshichang"></i>
                        </span>
                    </div>
                </div>
                <div class="top-comp atn-right-cell-b">
                    <span class="altm-icon">
                        <el-tooltip class="item" effect="dark" placement="top-start">
                            <div slot="content">
                                <div>{{$t('statisticsUp.topNumber.tip11')}}</div>
                            </div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <span class="altm-name">{{$t('statisticsUp.topNumber.averageFirstResponseTime')}}</span>
                    <span class="altm-type">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="firstResponseTimeData"></ration-text>
                </div>
            </div>
            <div class="atn-right-cell">
                <div class="atn-right-cell-t">
                    <div class="atn-right-cell-t-l">
                        <span class="atn-right-cell-t-l-num">{{artificialData.totalRate && artificialData.totalRate != 0 ? (Number(artificialData.totalRate)*100).toFixed(2) + "%"  : '0.00%'}}</span>
                        <!-- <span class="atn-right-cell-t-l-uni">分钟</span> -->
                    </div>
                    <div class="atn-right-cell-t-r">
                        <span class="atn-left-t-icon b-color-lg">
                            <i class="iconfont guoran-zhuanrengong"></i>
                        </span>
                    </div>
                </div>
                <div class="top-comp atn-right-cell-b">
                    <span class="altm-icon">
                        <el-tooltip class="item" effect="dark" :content="$t('statisticsUp.topNumber.tip4')"
                            placement="top-start">
                            <div slot="content">
                                <div>{{$t('statisticsUp.topNumber.tip5')}}</div>
                                <div>{{$t('statisticsUp.topNumber.tip6')}}</div>
                            </div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <span class="altm-name">{{$t('statisticsUp.laborConversionRate')}}</span>
                    <span class="altm-type">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="artificialData"></ration-text>
                </div>
            </div>
            <div class="atn-right-cell">
                <div class="atn-right-cell-t">
                    <div class="atn-right-cell-t-l">
                        <span class="atn-right-cell-t-l-num">{{recognitionRateData.totalRate && recognitionRateData.totalRate != 0 ? (Number(recognitionRateData.totalRate)*100).toFixed(2) + "%" : '0.00%'}}</span>
                    </div>
                    <div class="atn-right-cell-t-r">
                        <span class="atn-left-t-icon b-color-b">
                            <i class="iconfont guoran-tongyichicun-shibieshuai"></i>
                        </span>
                    </div>
                </div>
                <div class="top-comp atn-right-cell-b">
                    <span class="altm-icon">
                        <el-tooltip class="item" effect="dark" placement="top-start">
                            <div slot="content">
                                <div>{{$t('statisticsUp.topNumber.tip7')}}</div>
                                <div>{{$t('statisticsUp.topNumber.tip8')}}</div>
                            </div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <span class="altm-name">{{$t('statisticsUp.recognitionRate')}}</span>
                    <span class="altm-type">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="recognitionRateData"></ration-text>
                </div>
            </div>
            <div class="atn-right-cell">
                <div class="atn-right-cell-t">
                    <div class="atn-right-cell-t-l">
                        <span class="atn-right-cell-t-l-num">{{avResponseTimeData.respTime || 0}}</span>
                        <span class="atn-right-cell-t-l-uni">S</span>
                    </div>
                    <div class="atn-right-cell-t-r">
                        <span class="atn-left-t-icon b-color-g">
                            <i class="iconfont guoran-tongyichicun-jiqirenpingjunxiangyingshichang"></i>
                        </span>
                    </div>
                </div>
                <div class="top-comp atn-right-cell-b">
                    <span class="altm-icon">
                        <el-tooltip class="item" effect="dark" placement="top-start">
                            <div slot="content">
                                <div>{{$t('statisticsUp.topNumber.tip9')}}</div>
                                <div>{{$t('statisticsUp.topNumber.tip10')}}</div>
                            </div>
                            <i class="el-icon-warning-outline"></i>
                        </el-tooltip>
                    </span>
                    <span class="altm-name">{{$t('statisticsUp.topNumber.averageResponseTime')}}</span>
                    <span class="altm-type">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="avResponseTimeData"></ration-text>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import RationText from './ratio-text.vue'
export default {
    components:{RationText},
    props:{
        solutionData:{
            type:Object,
            default(){
                return {}
            }
        },
        receptionData:{
            type:Object,
            default(){
                return {}
            }
        },
        sessionData:{
            type:Object,
            default(){
                return {}
            }
        },
        artificialData:{
            type:Object,
            default(){
                return {}
            }
        },
        messageData:{
            type:Object,
            default(){
                return {}
            }
        },
        firstResponseTimeData:{
            type:Object,
            default(){
                return {}
            }
        },
        avResponseTimeData:{
            type:Object,
            default(){
                return {}
            }
        },
        recognitionRateData:{
            type:Object,
            default(){
                return {}
            }
        },
        loading:{
            type:Boolean,
            default:true
        }
    },
    data() {
        return {
            defaultTop: true,
            mainId: ""
            // receptionData: {
            //     pNumber: 546,
            //     pTypeNumber: 0.5
            // }
        }
    },
    mounted() {
       this.mainId = localStorage.getItem('_mainId');
       if (this.mainId == 'ab0f90737c8b4f2d85ba2157e4473110' || this.mainId == '969a139de1df48c6965ff9014777fbae' || this.mainId == 'b52d52cd3533409fb4b75af79868575f') {
            this.defaultTop = false;
       }
    },
    watch:{
        loading(n){
            if(!n){
                this.initData()
            }
        },
        // recognitionRateData:{
        //     handler(n){
        //         console.log(n,'识别率');
        //     },
        //     deep:true
        // }
    },
    methods:{
        initData(){
            var chartDom = document.getElementById('echar-atn-left-b');
            var myChart = echarts.init(chartDom);
            var option;
            let data = [], date = [];
            this.receptionData.data && this.receptionData.data.length > 0 && this.receptionData.data.forEach((item,index) => {
                data.push(item['访问用户']);
                date.push(item['日期']);
            })
            // for (let i = 1; i < 20; i++) {
            //     data.push(Math.random() * 30);
            //     date.push(i);
            // }
            option = {
                tooltip: {
                    show: false,
                    trigger: 'axis',
                    position: function (pt) {
                        return [pt[0], '10%'];
                    }
                },
                title: {
                    show: false,
                    left: 'center',
                    text: ''
                },
                toolbox: {
                    show: false,
                    feature: {
                        dataZoom: {
                            yAxisIndex: 'none'
                        },
                        restore: {},
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    show: false,
                    type: 'category',
                    boundaryGap: false,
                    data: date
                },
                yAxis: {
                    show: false,
                    type: 'value',
                    min: 0,
                    boundaryGap: [0, '100%']
                },
                grid: {
                    show: false,
                    top: '0%',
                    right: '0%',
                    bottom: '0%',
                    left: '00%'
                },
                // dataZoom: [
                //     {
                //         type: 'inside',
                //         start: 0,
                //         end: 10
                //     },
                //     {
                //         start: 0,
                //         end: 10
                //     }
                // ],
                series: [
                    {
                        name: 'Fake Data',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        sampling: 'lttb',
                        itemStyle: {
                            color: 'rgb(69, 143, 255)'
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(69, 143, 255)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(255, 255, 255)'
                                }
                            ])
                        },
                        data: data
                    }
                ]
            };

            option && myChart.setOption(option);

            window.addEventListener("resize", () => {
                myChart.resize();
            });
        }
    }
}
</script>
<style scoped lang="less">
#ask-top-number {
    width: 100%;
    height: 266px;
    display: flex;
    justify-content: space-between;
    font-family: Microsoft YaHei;

    .top-comp {
        color: #616161;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;

        .altm-name {
            margin-left: 2px;
            font-size: 14px;
            color: #616161;
            text-align: left;
        }

        .altm-type {
            margin-left: 6px;
            margin-right: 4px;
            color: #A9B3C6;
        }

        .altm-type-num {

            // height: 16px;
            // line-height: 16px;
            background: #F5F7FB;
            border-radius: 8px;
            padding: 0 3px;

            .altm-type-icon-up {
                color: #FE5965;

                i {
                    font-size: 12px;
                }

            }

            .altm-type-icon-down {
                color: #09DDD5;

                i {
                    font-size: 12px;
                }

            }
        }
        .altm-icon{
            i{
                font-size: 14px;
                margin-top: 2px;
            }
        }
        
    }

    .atn-left {
        height: 252px;
        width: 25%;
        margin-right: 14px;
        margin-bottom: 14px;
        flex: none;
        background-color: white;
        box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
        border-radius: 5px;
        overflow: hidden;

        .atn-left-t {
            margin: 20px 20px 10px 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .atn-left-t-icon {
                display: block;
                width: 46px;
                height: 46px;
                line-height: 46px;
                text-align: center;
                background: #ECF4FF;
                border-radius: 50%;
                color: #458FFF;

                i {
                    font-size: 22px;
                }
            }

            .atn-left-t-num {
                margin-top: 12px;
                margin-bottom: 26px;
                height: 21px;
                font-size: 26px;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #010101;
                display: flex;
                justify-content: flex-start;
                align-items: baseline;

                .atn-right-cell-t-l-uni-right {
                    font-size: 14px;
                    margin-left: 4px;
                    color: #A9B3C6;
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    .arctlur-num-orange{
                        color: white;
                        display: inline-block;
                        background-color: #FF8F97;
                        height: 16px;
                        line-height: 16px;
                        padding: 0 6px;
                        border-radius: 10px 10px 10px 0;
                        margin: 0 4px;
                        font-size: 13px;
                        font-weight: bold;
                    }
                    .arctlur-num-blue{
                        color: white;
                        display: inline-block;
                        background-color: #89B8FF;
                        height: 16px;
                        line-height: 16px;
                        padding: 0 6px;
                        border-radius: 10px 10px 10px 0;
                        margin: 0 4px;
                        font-size: 13px;
                        font-weight: bold;
                    }
                }
            }

            .atn-left-t-msg {}
        }

    }
    .atn-right-pre {
        flex: none;
        width: 25%;
        flex-wrap: wrap;
        justify-content: space-between;
        .atn-left-t-pre {
            background: white;
            width: calc(100% - 12px);
            height: 119px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .atn-left-t-num {
                width: calc(100% - 40px);
                padding: 20px 20px 0 20px;
                line-height: 26px;
                font-size: 26px;
                font-family: Microsoft YaHei;
                font-weight: 600;
                color: #010101;
                display: flex;
                justify-content: flex-start;
                align-items: baseline;

                .atn-right-cell-t-l-uni-right {
                    font-size: 14px;
                    margin-left: 4px;
                    color: #A9B3C6;
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    .arctlur-num-orange{
                        color: white;
                        display: inline-block;
                        background-color: #FF8F97;
                        height: 16px;
                        line-height: 16px;
                        padding: 0 6px;
                        border-radius: 10px 10px 10px 0;
                        margin: 0 4px;
                        font-size: 13px;
                        font-weight: bold;
                    }
                    .arctlur-num-blue{
                        color: white;
                        display: inline-block;
                        background-color: #89B8FF;
                        height: 16px;
                        line-height: 16px;
                        padding: 0 6px;
                        border-radius: 10px 10px 10px 0;
                        margin: 0 4px;
                        font-size: 13px;
                        font-weight: bold;
                    }
                }
            }

            .atn-left-t-msg {
                padding: 0 20px 20px 20px;
            }
        }
    }

    .atn-right {
        flex: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .atn-right-cell {
            width: calc(33.3% - 8px);
            background-color: white;
            height: 119px;
            box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 14px;

            .atn-right-cell-t {
                padding: 20px 20px 0 20px;
                display: flex;
                justify-content: space-between;

                .atn-right-cell-t-l {
                    height: 26px;
                    display: flex;
                    vertical-align: bottom;
                    align-items: flex-end;

                    .atn-right-cell-t-l-num {
                        font-size: 24px;
                        height: 24px;
                        line-height: 26px;
                        font-weight: 600;
                        color: #010101;
                        margin-right: 3px;
                    }
                    .arctlur-num-orange{
                        color: white;
                        display: inline-block;
                        background-color: #FF8F97;
                        height: 16px;
                        // line-height: 16px;
                        padding: 0 6px;
                        border-radius: 10px 10px 10px 0;
                        margin: 0 4px;
                        font-size: 13px;
                        font-weight: bold;
                    }
                    .atn-right-cell-t-l-uni {
                        font-size: 12px;
                        font-weight: 400;
                        color: #A9B3C6;
                        margin-bottom: 1px;
                    }
                    .atn-right-cell-t-l-uni-right{
                        font-size: 14px;
                        margin-left: 4px;
                        color: #A9B3C6;
                        display: flex;
                        align-items: center;
                        .arctlur-num{
                            color: white;
                            display: inline-block;
                            background-color: #FF8F97;
                            height: 16px;
                            line-height: 16px;
                            padding: 0 6px;
                            border-radius: 10px 10px 10px 0;
                            margin: 0 4px;
                            font-size: 13px;
                            font-weight: bold;
                        }
                    }
                }

                .atn-left-t-icon {
                    display: block;
                    width: 46px;
                    height: 46px;
                    line-height: 46px;
                    text-align: center;
                    border-radius: 50%;

                    i {
                        font-size: 22px;
                    }
                }

                .b-color-lg {
                    background: #E1FAF9;
                    color: #09DDD5;
                }

                .b-color-b {
                    background: #ECF1FF;
                    color: #366AFF;
                }

                .b-color-g {
                    background: #DDF8E9;
                    color: #3CD781;
                }
            }

            .atn-right-cell-b {
                padding: 0 20px 20px 20px;
            }
        }
    }
}
</style>