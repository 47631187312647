<template>
    <div id="transworkorder">
        <div class="asksta-simple-title">
            <div class="ast-left" :style="$i18n.locale === 'en' ? 'width:250px' : ''">
                <span class="ast-left-name">{{$t('statisticsUp.transWorkOrder.title')}}</span>
                <span class="ast-left-icon">
                    <el-tooltip class="item" effect="dark"  placement="top-start">
                        <div slot="content">
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.totalTransOrderSession')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip1')}}</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip2')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.TotalIntentTransOrderSession')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip3')}}</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip2')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.unrecognizedSession')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip4')}}</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip2')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.answerUselessSession')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip5')}}</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip2')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.directSession')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip6')}}</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip2')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.totalNumberOfQuestions')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip7')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.identifyIntentQuestion')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip8')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.unrecognizedQuestion')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip9')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.answerUselessQuestion')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip10')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.transWorkOrder.directQuestion')}}：</div>
                            <div>{{$t('statisticsUp.transWorkOrder.tip11')}}</div>
                        </div>
                        <em class="el-icon-warning-outline"></em>
                    </el-tooltip>
                </span>
            </div>
            <div class="ast-right"  v-show="!(sessionTotalData.totalNum === 0 && questionTotalData.totalNum === 0)">
                <div class="ast-right-top">
                    <div class="ast-right-cell ast-right-cell-bg-lb-worker" :style="{'margin-right':mr + 'px',width:typeof(tipsWidth) === 'string' ? tipsWidth :  tipsWidth + 'px'}">
                        <span  class="arc-icon total-session"></span>
                        <span  class="arc-name">{{$t('statisticsUp.transWorkOrder.totalTransOrderSession')}}:</span>
                        <span class="arc-num">{{sessionTotalData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="sessionTotalData"></ration-text>
                    </div>
                    <div class="ast-right-cell">
                        <span @click="switchItem(1)" :class="['arc-icon',topActive1 ? 'color-b-01' : 'color-gray']"></span>
                        <span @click="switchItem(1)" class="arc-name">{{$t('statisticsUp.transWorkOrder.TotalIntentTransOrderSession')}}:</span>
                        <span class="arc-num">{{intentWorkOrderSessionData.totalNum}}</span>
                        <span class="proportion-num">({{intentWorkOrderSessionData.proportion}})</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="intentWorkOrderSessionData"></ration-text>
                    </div>

                    <div class="ast-right-cell">
                        <span @click="switchItem(2)" :class="['arc-icon',topActive2 ? 'color-b-02' : 'color-gray']"></span>
                        <span @click="switchItem(2)" class="arc-name">{{$t('statisticsUp.transWorkOrder.unrecognizedSession')}}:</span>
                        <span class="arc-num">{{unrecognitionWorkOrderSessionData.totalNum}}</span>
                        <span class="proportion-num">({{unrecognitionWorkOrderSessionData.proportion}})</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="unrecognitionWorkOrderSessionData"></ration-text>
                    </div>
                    <div class="ast-right-cell">
                        <span @click="switchItem(3)" :class="['arc-icon',topActive3 ? 'color-b-03' : 'color-gray']"></span>
                        <span @click="switchItem(3)" class="arc-name">{{$t('statisticsUp.transWorkOrder.answerUselessSession')}}:</span>
                        <span class="arc-num">{{answerWorkOrderSessionData.totalNum}}</span>
                        <span class="proportion-num">({{answerWorkOrderSessionData.proportion}})</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="answerWorkOrderSessionData"></ration-text>
                    </div>
                    <div class="ast-right-cell">
                        <span @click="switchItem(4)" :class="['arc-icon',topActive4 ? 'color-b-04' : 'color-gray']"></span>
                        <span @click="switchItem(4)" class="arc-name">{{$t('statisticsUp.transWorkOrder.directSession')}}:</span>
                        <span class="arc-num">{{directWorkOrderSessionData.totalNum}}</span>
                        <span class="proportion-num">({{directWorkOrderSessionData.proportion}})</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="directWorkOrderSessionData"></ration-text>
                    </div>
                </div>
                <div class="ast-right-bottom">
                    <div class="ast-right-cell ast-right-cell-bg-lp-worker" :style="{'margin-right':mr + 'px',width:typeof(tipsWidth) === 'string' ? tipsWidth :  tipsWidth + 'px'}">
                        <span :class="['arc-icon','total-question']"></span>
                        <span  class="arc-name">{{$t('statisticsUp.transWorkOrder.totalNumberOfQuestions')}}:</span>
                        <span class="arc-num">{{questionTotalData.totalNum}}</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="questionTotalData"></ration-text>
                    </div>
                    <div class="ast-right-cell">
                        <span @click="switchItem(5)" :class="['arc-icon',bottomActive1 ? 'color-p-01' : 'color-gray']"></span>
                        <span @click="switchItem(5)" class="arc-name">{{$t('statisticsUp.transWorkOrder.identifyIntentQuestion')}}:</span>
                        <span class="arc-num">{{intentWorkOrderQuestionData.totalNum}}</span>
                        <span class="proportion-num">({{intentWorkOrderQuestionData.proportion}})</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="intentWorkOrderQuestionData"></ration-text>
                    </div>

                    <div class="ast-right-cell">
                        <span @click="switchItem(6)" :class="['arc-icon',bottomActive2 ? 'color-p-02' : 'color-gray']"></span>
                        <span @click="switchItem(6)" class="arc-name">{{$t('statisticsUp.transWorkOrder.unrecognizedQuestion')}}:</span>
                        <span class="arc-num">{{unrecognitionWorkOrderQuestionData.totalNum}}</span>
                        <span class="proportion-num">({{unrecognitionWorkOrderQuestionData.proportion}})</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="unrecognitionWorkOrderQuestionData"></ration-text>
                    </div>
                    <div class="ast-right-cell">
                        <span @click="switchItem(7)" :class="['arc-icon',bottomActive3 ? 'color-p-03' : 'color-gray']"></span>
                        <span @click="switchItem(7)" class="arc-name">{{$t('statisticsUp.transWorkOrder.answerUselessQuestion')}}:</span>
                        <span class="arc-num">{{answerWorkOrderQuestionData.totalNum}}</span>
                        <span class="proportion-num">({{answerWorkOrderQuestionData.proportion}})</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="answerWorkOrderQuestionData"></ration-text>
                    </div>
                    <div class="ast-right-cell">
                        <span @click="switchItem(8)" :class="['arc-icon',bottomActive4 ? 'color-p-04' : 'color-gray']"></span>
                        <span @click="switchItem(8)" class="arc-name">{{$t('statisticsUp.transWorkOrder.directQuestion')}}:</span>
                        <span class="arc-num">{{directWorkOrderQuestionData.totalNum}}</span>
                        <span class="proportion-num">({{directWorkOrderQuestionData.proportion}})</span>
                        <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                        <ration-text :data="directWorkOrderQuestionData"></ration-text>
                    </div>
                </div>
            </div>
        </div>
         <div style="height: 350px" v-if="sessionTotalData.totalNum === 0 && questionTotalData.totalNum === 0">
            <no-data-echarts></no-data-echarts>
        </div>
        <div class="asksta-receivenum-char">
            <div style="height: 350px" id="asksta-transworkorder-char"></div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import RationText from './ratio-text.vue';
import NoDataEcharts from './noDataEcharts.vue'
export default {
    data() {
        return {
            myChart: null,
            showArr:[],
            topActive1: true,
            topActive2: true,
            topActive3: true,
            topActive4: true,
            bottomActive1: true,
            bottomActive2: true,
            bottomActive3: true,
            bottomActive4: true,
            sessionTotalData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
            },
            questionTotalData:{
                totalNum:0,
                ratio:'0.00%',
                ratioType:"0",
            },
            intentWorkOrderSessionData:{
                totalNum:0,
                proportion:'0.00%',
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            unrecognitionWorkOrderSessionData:{
                totalNum:0,
                proportion:'0.00%',
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            answerWorkOrderSessionData:{
                totalNum:0,
                proportion:'0.00%',
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            directWorkOrderSessionData:{
                totalNum:0,
                proportion:'0.00%',
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            intentWorkOrderQuestionData:{
                totalNum:0,
                proportion:'0.00%',
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            unrecognitionWorkOrderQuestionData:{
                totalNum:0,
                proportion:'0.00%',
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            answerWorkOrderQuestionData:{
                totalNum:0,
                proportion:'0.00%',
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            directWorkOrderQuestionData:{
                totalNum:0,
                proportion:'0.00%',
                ratio:'0.00%',
                ratioType:"0",
                dataDetailDtoList:[]
            },
            mr:0,
            mr2:0,
            rightTitleWidth:0,
            tipsWidth:'auto'
        }
    },
    components:{RationText,NoDataEcharts},
    props:{
        transWorkerOrderData:{
            type:Object,
            default(){
                return {}
            }
        },
        loading:{
            type:Boolean,
            default:true
        },
        isInit:{
            type:Boolean,
            default:true
        }
    },
    watch:{
        isInit:{
            handler(n){
                if(n){
                    this.showArr = [1, 2, 3, 4, 5, 6, 7, 8];
                }
            },
            immediate:true
        },
        transWorkerOrderData:{
            handler(n){
                if(!this.loading){
                    this.handlerData(n);
                }
            },
            deep:true,
            immediate:true
        },
    },
    methods: {
        // 处理数据
        handlerData(n){
            if(n.sourceData && n.sourceData.sessionQuestionList && n.sourceData.sessionQuestionList.length > 0){
                n.sourceData.sessionQuestionList.forEach((item,index) => {
                    if(item.type === 'WORKORDER_SOLVE_SESSION'){
                        this.sessionTotalData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                        }
                    } else if(item.type === 'WORKORDER_SOLVE_QUESTION'){
                        this.questionTotalData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                        }
                    } else if(item.type === 'INTENT_WORKORDER_SESSION'){
                        this.intentWorkOrderSessionData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'UNRECOGNITION_WORKORDER_SESSION'){
                        this.unrecognitionWorkOrderSessionData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'ANSWER_USELESS_WORKORDER_SESSION'){
                        this.answerWorkOrderSessionData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'DIRECT_WORKORDER_SESSION'){
                        this.directWorkOrderSessionData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'INTENT_WORKORDER_QUESTION'){
                        this.intentWorkOrderQuestionData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'UNRECOGNITION_WORKORDER_QUESTION'){
                        this.unrecognitionWorkOrderQuestionData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'ANSWER_USELESS_WORKORDER_QUESTION'){
                        this.answerWorkOrderQuestionData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    } else if(item.type === 'DIRECT_WORKORDER_QUESTION'){
                        this.directWorkOrderQuestionData = {
                            totalNum:item.totalNum,
                            proportion:item.proportion && item.proportion !== '0%' ? item.proportion : '0.00%',
                            ratio:item.ratio && item.ratio !== '0%' ? item.ratio : '0.00%',
                            ratioType: item.ratio.charAt(0),
                            dataDetailDtoList:item.dataDetailDtoList,
                        }
                    }
                })
                this.tipsWidth = 'auto';
                setTimeout(() => {
                    this.$nextTick(() => {
                        if(!(this.sessionTotalData.totalNum === 0 && this.questionTotalData.totalNum === 0)){
                            let top = document.querySelector('.ast-right-cell-bg-lb-worker').clientWidth;
                            let bottom = document.querySelector('.ast-right-cell-bg-lp-worker').clientWidth;
                            
                            if(top > bottom){
                                this.tipsWidth = top - 12;
                            } else {
                                this.tipsWidth = bottom - 12;
                            }
                            this.mr = Math.abs(330 - this.tipsWidth) + 16
                            this.mr2 = Math.abs(330 - this.tipsWidth) + 16

                        }
                        this.iniEchart([1, 2, 3, 4, 5, 6, 7, 8]);
                    })
                },100)
            } else {
                this.sessionTotalData = {
                    totalNum:0,
                    ratio:'0.00%',
                    ratioType:"0",
                }
                this.questionTotalData = {
                    totalNum:0,
                    ratio:'0.00%',
                    ratioType:"0",
                }
            }
        },
        switchItem(index) {
            if (index == 1) {
                if(this.showArr.length === 1 && this.topActive1){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.topActive1 = !this.topActive1;
                }
                
            }
            if (index == 2) {
                if(this.showArr.length === 1 && this.topActive2){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.topActive2 = !this.topActive2;
                }
            }
            if (index == 3) {
                if(this.showArr.length === 1 && this.topActive3){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.topActive3 = !this.topActive3;
                }
            }
            if (index == 4) {
                if(this.showArr.length === 1 && this.topActive4){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.topActive4 = !this.topActive4;
                }
            }
            if (index == 5) {
                if(this.showArr.length === 1 && this.bottomActive1){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.bottomActive1 = !this.bottomActive1;
                }
            }
            if (index == 6) {
                if(this.showArr.length === 1 && this.bottomActive2){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.bottomActive2 = !this.bottomActive2;
                }
            }
            if (index == 7) {
                if(this.showArr.length === 1 && this.bottomActive3){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.bottomActive3 = !this.bottomActive3;
                }
            }
            if (index == 8) {
                if(this.showArr.length === 1 && this.bottomActive4){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.bottomActive4 = !this.bottomActive4;
                }
            }
            
            let arr = [];
            if (this.topActive1) {
                arr.push(1);
            }
            if (this.topActive2) {
                arr.push(2);
            }
            if (this.topActive3) {
                arr.push(3);
            }
            if (this.topActive4) {
                arr.push(4);
            }
            if (this.bottomActive1) {
                arr.push(5);
            }
            if (this.bottomActive2) {
                arr.push(6);
            }
            if (this.bottomActive3) {
                arr.push(7);
            }
            if (this.bottomActive4) {
                arr.push(8);
            }
            this.showArr = arr;
            this.iniEchart(arr);
        },
        iniEchart(showArr) {
            var chartDom = document.getElementById('asksta-transworkorder-char');
            if (this.myChart != null) {
                this.myChart.clear();
            }
            this.myChart = echarts.init(chartDom);
            let xAxisData = [],topData1 = [],topData2 = [],topData3 = [],topData4 = [],bottomData1 = [],bottomData2 = [],bottomData3 = [],bottomData4 = [];
            this.intentWorkOrderSessionData.dataDetailDtoList && this.intentWorkOrderSessionData.dataDetailDtoList.length > 0 && this.intentWorkOrderSessionData.dataDetailDtoList.forEach((item,index) => {
                xAxisData.push(item.time);
                // topData1.push(item.num);
                topData1.push({
                    value:item.num
                })
            })
            this.unrecognitionWorkOrderSessionData.dataDetailDtoList && this.unrecognitionWorkOrderSessionData.dataDetailDtoList.length > 0 && this.unrecognitionWorkOrderSessionData.dataDetailDtoList.forEach((item,index) => {
                // topData2.push(item.num);
                topData2.push({
                    value:item.num
                })
            })
            this.answerWorkOrderSessionData.dataDetailDtoList && this.answerWorkOrderSessionData.dataDetailDtoList.length > 0 && this.answerWorkOrderSessionData.dataDetailDtoList.forEach((item,index) => {
                // topData3.push(item.num);
                topData3.push({
                    value:item.num
                })
            })
            this.directWorkOrderSessionData.dataDetailDtoList && this.directWorkOrderSessionData.dataDetailDtoList.length > 0 && this.directWorkOrderSessionData.dataDetailDtoList.forEach((item,index) => {
                // bottomData1.push(item.num);
                topData4.push({
                    value:item.num
                })
            })
            this.intentWorkOrderQuestionData.dataDetailDtoList && this.intentWorkOrderQuestionData.dataDetailDtoList.length > 0 && this.intentWorkOrderQuestionData.dataDetailDtoList.forEach((item,index) => {
                // bottomData1.push(item.num);
                bottomData1.push({
                    value:item.num
                })
            })
            this.unrecognitionWorkOrderQuestionData.dataDetailDtoList && this.unrecognitionWorkOrderQuestionData.dataDetailDtoList.length > 0 && this.unrecognitionWorkOrderQuestionData.dataDetailDtoList.forEach((item,index) => {
                // bottomData2.push(item.num);
                bottomData2.push({
                    value:item.num
                })
            })
            this.answerWorkOrderQuestionData.dataDetailDtoList && this.answerWorkOrderQuestionData.dataDetailDtoList.length > 0 && this.answerWorkOrderQuestionData.dataDetailDtoList.forEach((item,index) => {
                // bottomData3.push(item.num);
                bottomData3.push({
                    value:item.num
                })
            })
            this.directWorkOrderQuestionData.dataDetailDtoList && this.directWorkOrderQuestionData.dataDetailDtoList.length > 0 && this.directWorkOrderQuestionData.dataDetailDtoList.forEach((item,index) => {
                // bottomData4.push(item.num);
                bottomData4.push({
                    value:item.num
                })
            })
            var emphasisStyle = {
                // itemStyle: {
                //     shadowBlur: 10,
                // }
            };
            let askSeries = [
                    {
                        name: this.$t('statisticsUp.transWorkOrder.TotalIntentTransOrderSession'),
                        type: 'bar',
                        stack: 'one',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: topData1,
                        itemStyle: {//自定义颜色
                            normal:{ 
                                color: "#00C4BD" 
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.transWorkOrder.unrecognizedSession'),
                        type: 'bar',
                        stack: 'one',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: topData2,
                        itemStyle: {//自定义颜色
                            normal:{ 
                                color: "#3CDB83" 
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.transWorkOrder.answerUselessSession'),
                        type: 'bar',
                        stack: 'one',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: topData3,
                        itemStyle: {//自定义颜色
                            normal:{ 
                                color: "#69F0A5" 
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.transWorkOrder.directSession'),
                        type: 'bar',
                        stack: 'one',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: topData4,
                        itemStyle: {//自定义颜色
                            normal:{ 
                                color: "#BEF691" 
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.transWorkOrder.identifyIntentQuestion'),
                        type: 'bar',
                        stack: 'two',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: bottomData1,
                        itemStyle: {//自定义颜色
                            normal:{ 
                                color: "#FF7D4F" 
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.transWorkOrder.unrecognizedQuestion'),
                        type: 'bar',
                        stack: 'two',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: bottomData2,
                        itemStyle: {//自定义颜色
                            normal:{ 
                                color: "#FF9F65" 
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.transWorkOrder.answerUselessQuestion'),
                        type: 'bar',
                        stack: 'two',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: bottomData3,
                        itemStyle: {//自定义颜色
                            normal:{ 
                                color: "#FFBF6B" 
                            },
                        },
                    },
                    {
                        name: this.$t('statisticsUp.transWorkOrder.directQuestion'),
                        type: 'bar',
                        stack: 'two',
                        barMaxWidth: '20px',
                        emphasis: emphasisStyle,
                        data: bottomData4,
                        itemStyle: {//自定义颜色
                            normal:{ 
                                color: "#FFF075" 
                            },
                        },
                    }
            ]

            let series = [], color = [];
            // if (showArr.indexOf(1) != -1 || showArr.indexOf(2) != -1) {
            if (showArr.indexOf(1) != -1) {
                series.push(askSeries[0])
                // color.push('#00C4BD')
            }
            if (showArr.indexOf(2) != -1) {
                series.push(askSeries[1])
                // color.push('#3CDB83') 
            }
            // }
            if (showArr.indexOf(3) != -1) {
                series.push(askSeries[2])
                // color.push('#69F0A5') 
            }
            if (showArr.indexOf(4) != -1) {
                series.push(askSeries[3])
                // color.push('#BEF691')
            }
            if (showArr.indexOf(5) != -1) {
                series.push(askSeries[4])
                // color.push('#FF7D4F')
            }
            if (showArr.indexOf(6) != -1) {
                series.push(askSeries[5])
                // color.push('#FF9F65')
            }
            if (showArr.indexOf(7) != -1) {
                series.push(askSeries[6])
                // color.push('#FFBF6B')
            }
             if (showArr.indexOf(8) != -1) {
                series.push(askSeries[7])
                // color.push('#FFF075')
            }
            // 处理  首尾圆角
            let oneList = [],twoList = [];
            series.forEach((seriesItem,seriesIndex) => {
                if(seriesItem.stack === 'one'){
                    oneList.push(seriesItem);
                } else {
                    twoList.push(seriesItem);
                }
            })
            let oneList2 = JSON.parse(JSON.stringify(oneList)).reverse();
            if(oneList.length > 0){
                // 设置柱条顶层圆角
                for (let i in oneList2[0].data) { // 遍历所有的x轴数据(总数为n),为单根柱条提供data下标（0 ~ n-1）
                    for (let item of oneList2) {  //遍历单根柱条
                        if (Number(item.data[i].value) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]  因为是reverse 所以设置的是反方向
                            item.data[i]['itemStyle'] = { barBorderRadius: [50, 50, 0, 0] };
                            break;  //退出该柱条的循环
                        }
                    }
                }
                oneList2 = oneList2.reverse();
                for (let i in oneList2[oneList2.length-1].data) {
                    for (let index = 0; index < oneList2.length; index++) {
                        let item = oneList2[index];
                        if (Number(item.data[i].value) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
                            //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]  因为是reverse 所以设置的是反方向
                            if(item.data[i]['itemStyle']){
                                item.data[i]['itemStyle'] = { barBorderRadius:  [50, 50, 50, 50] };
                            } else {
                                item.data[i]['itemStyle'] = { barBorderRadius:  [0, 0, 50, 50] };
                            }
                            break;  //退出该柱条的循环
                        }
                        
                    }
                
                }
            }
            let twoList2 = JSON.parse(JSON.stringify(twoList)).reverse();
            if(twoList.length > 0){
                for (let i in twoList2[0].data) { // 遍历所有的x轴数据(总数为n),为单根柱条提供data下标（0 ~ n-1）
                    for (let item of twoList2) {  //遍历单根柱条
                        if (Number(item.data[i].value) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]  因为是reverse 所以设置的是反方向
                            item.data[i]['itemStyle'] = { barBorderRadius: [50, 50, 0, 0] };
                            break;  //退出该柱条的循环
                        }
                    }
                }
                twoList2 = twoList2.reverse();
                for (let i in twoList2[twoList2.length-1].data) {
                    for (let item of twoList2) {  //遍历单根柱条
                        if (Number(item.data[i].value) > 0) { // 找到第一个不为0的值，为该项添加itemStyle
                        //这里设置柱形图圆角 [左上角，右上角，右下角，左下角] 因为是reverse 所以设置的是反方向
                            if(item.data[i]['itemStyle']){
                                item.data[i]['itemStyle'] = { barBorderRadius:  [50, 50, 50, 50] };
                            } else {
                                item.data[i]['itemStyle'] = { barBorderRadius:  [0, 0, 50, 50] };
                            }
                            break;  //退出该柱条的循环
                        }
                    }
                }
            }
            // let _oneList3 = JSON.parse(JSON.stringify(oneList2)).reverse();
            // let _twoList3 = JSON.parse(JSON.stringify(twoList2)).reverse();
            series = [...oneList2,...twoList2]

            // console.log(series,'处理后的series');
            let option = {};
            
            if(this.sessionTotalData.totalNum === 0 && this.questionTotalData.totalNum === 0 ){
                // option = {
                //     title: {
                //         text: '暂无数据',
                //         x: 'center',
                //         y: 'center',
                //         textStyle: {
                //             fontSize: 16,
                //             fontWeight: 'normal',
                //             color:'#A9B3C6'
                //         }
                //     }
                // }
            } else {
                option = {
                    // legend: {
                    //     data: ['bar', 'bar2', 'bar3', 'bar4'],
                    //     left: '10%'
                    // },
                    // 机器人独立解决会话数 转工单解决会话数  转IM人工解决会话数
                    // 机器人独立解决问题数  转工单解决问题数 转IM人工解决问题数
                    // color: ["#458FFF", "#89B8FF", "#BBD6FF", "#FF8F97", "#FFBABF", "#FFDDDF"],
                    xAxis: {
                        data: xAxisData,
                        axisLine: { onZero: true },
                        splitLine: { show: false },
                        splitArea: { show: false },
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '',
                        // min: 0,
                        splitLine: { // 设置网格线
                            show:true,
                            lineStyle:{
                                color:"#E0E6F7",

                            }
                        },
                        // max: 250,
                        // interval: 50,
                        axisLine: {
                            show: false, //隐藏y轴
                        },
                        axisTick: {
                            show: false // 不显示坐标轴刻度线
                        },
                        axisLabel: {
                            formatter: '{value}',
                            color: '#A9B3C6',
                        },
                        itemStyle: {
                            normal: {
                                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                                barBorderRadius: [50, 50, 50, 50]
                            }
                        }
                    },
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            },
                        },
                        textStyle:{
                            align:'left'
                        }

                    },
                    grid: {
                        show: false,
                        top: '40',
                        right: '5%',
                        bottom: '40',
                        left: '5%'
                    },
                    series: [...series],
                };
            }
            option && this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        },
        windowWidth(){
            let allWidth = document.querySelector('#transworkorder').clientWidth;
            this.rightTitleWidth = allWidth - 100
        }
    },
    mounted(){
        // 初始化加载数据
        window.onload =  () => {
            // 监听窗口变化
            this.windowWidth();
        }
        // 监听页面窗口变化
        window.onresize =  () => {
            this.windowWidth();
        }

    }
}
</script>
<style lang="less" scoped>
@import "./../../../assets/less/statistics/statisticsCommon.less";
#transworkorder {
    height: 484px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    margin-top: 14px;
    overflow: hidden;
    .asksta-simple-title {
        .ast-left {
            width: 110px ;
        }
        .ast-right {
            height: 50px;
            display: inline-block;
            margin: 0 auto;

            .ast-right-cell {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 16px;
                font-size: 12px;
                line-height: 20px;
                width: 330px;
                .arc-icon {
                    margin-right: 4px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    cursor: pointer;
                    &.total-session{
                        background: #02A3CB;
                    }
                    &.total-question{
                        background: #FF581E;
                    }
                    &.color-b-01 {
                        background: #00C4BD;
                    }
                    &.color-b-02 {
                        background: #3CDB83;
                    }
                    &.color-b-03 {
                        background: #69F0A5;
                    }
                    &.color-b-04 {
                        background: #BEF691;
                    }
                    &.color-p-01 {
                        background: #FF7D4F;
                    }
                    &.color-p-02 {
                        background: #FF9F65;
                    }
                    &.color-p-03 {
                        background: #FFBF6B;
                    }
                    &.color-p-04 {
                        background: #FFF075;
                    }
                    &.color-gray {
                        background: #A9B3C6;
                    }
                }

                .arc-name {
                    cursor: pointer;
                    color: #000000;
                    // font-weight: 600;
                    white-space: nowrap;
                }

                .arc-num {
                    color: #000000;
                    // font-weight: 600;
                    margin-right: 8px;
                    margin-left: 6px;
                    white-space: nowrap;
                }
                .proportion-num{
                    color: #000000;
                    // font-weight: 600;
                    margin-right: 4px;
                    white-space: nowrap;
                }

                .arc-per-pre {
                    color: #A9B3C6;
                    white-space: nowrap;
                }

                .arc-type {
                    margin-left: 6px;
                    margin-right: 4px;
                }

            }

            .ast-right-cell-bg-lb-worker{
                background-color: rgba(2, 163, 203, 0.2);
                padding: 0 6px;
                border-radius: 10px;
                font-size: 14px;
                max-width: 300px;
                width: auto;
            }

            .ast-right-cell-bg-lp-worker {
                background-color: rgba(255, 88, 30, 0.2);
                padding: 0 6px;
                border-radius: 10px;
                font-size: 14px;
                max-width: 300px;
                width: auto;
            }
        }
    }
    .asksta-receivenum-char{
        margin-top: 30px;
    }
}
</style>