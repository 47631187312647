import { render, staticRenderFns } from "./AdvisoryQuestions.vue?vue&type=template&id=4cc741c9&scoped=true&"
import script from "./AdvisoryQuestions.vue?vue&type=script&lang=js&"
export * from "./AdvisoryQuestions.vue?vue&type=script&lang=js&"
import style0 from "./AdvisoryQuestions.vue?vue&type=style&index=0&id=4cc741c9&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc741c9",
  null
  
)

export default component.exports