<template>
    <div class="questionConsultation" >
        <div class="title with-handle-title">
            <span>
                {{$t('statisticsUp.questionConsultation.title')}}
                <el-tooltip class="item" effect="dark" :content="$t('statisticsUp.questionConsultation.tip')" placement="top-start">
                    <em class="el-icon-warning-outline"></em>
                </el-tooltip>
            </span>
            <el-button icon="iconfont guoran-a-24-06" @click="setQuestionType">{{$t('statisticsUp.questionConsultation.setQuestionType')}}</el-button>
        </div>
        <div v-show="!isEmipty" v-loading="loading" id="qwTypeEcharts1" ></div>
        <div class="legend-box" v-show="isEmipty">
            <no-data-echarts></no-data-echarts>
        </div>
        <intentQuestionDrewer 
            :visible.sync="visible" 
            :tableDataTree="activeDataTree"
            :drewerType.sync="drewerType"
            title="问题咨询详情">
        </intentQuestionDrewer>
    </div>
</template>

<script>
import NoDataEcharts from './noDataEcharts.vue';
import intentQuestionDrewer from './intentQuestionDrewer.vue'
import {v4 as uuidv4} from "uuid";
export default {
    data(){
        return {
            option:{},
            activeDataTree:[],
            visible:false,
            drewerType:'',

        }
    },
    components:{
        NoDataEcharts,
        intentQuestionDrewer
    },
    props:{
        data:{
            type:Array,
            default(){
                return []
            }
        },
        loading:{
            type:Boolean,
            default:true
        },
        isEmipty:{
            type:Boolean,
            default:false
        },
        allDataTree:{
            type:Object,
            default(){
                return {}
            }
        }
    },
   
    watch:{
        data:{
            handler(n){
                if(!this.loading){
                    this.option = {};
                    this.iniEchart(n);
                }
            },
            deep:true,
            immediate:true
        },
    },
    methods:{
        setQuestionType(){
            this.$emit('setQuestionType');
        },
        // 问题咨询占比
        iniEchart(data) {
            this.$nextTick(() => {
                // 基于准备好的dom，初始化echarts实例
                let qwTypmyChart = this.$echarts.init(
                    document.getElementById("qwTypeEcharts1")
                );
                
                // 绘制图表
                this.option = {
                    title: {
                        text: "",
                        subtext: "",
                        left: "center",
                    },
                    tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)",
                    },
                    legend: {
                        type:'scroll',//就这一个属性 设置legend滚动轴的出现
                        orient: 'vertical',  // 图例竖向排列
                        icon:"path://M512 0C229.376 0 0 228.864 0 512s228.864 512 512 512 512-228.864 512-512S795.136 0 512 0z m0 765.952c-140.288 0-253.952-113.664-253.952-253.952S371.712 258.048 512 258.048s253.952 113.664 253.952 253.952c0.512 140.288-113.664 253.952-253.952 253.952z",
                        align: 'left',
                        x:'right',      //可设定图例在左、右、居中
                        y:'center',     //可设定图例在上、下、居中
                        padding:[20,20,0,0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                        bottom: 30,
                        formatter: function (params) {
                            let name = ''
                            if(params.length>5){
                                name = params.slice(0,6) + '...'
                            } else {
                                name = params;
                            }
                            return name
                        }
                    },
                    color: ['#766BF0','#366AFF','#458FFF','#A0BDFF','#09DDD5','#57E97D','#FF9555','#FF8F97'],
                    series: [
                        {
                            name: this.$t('statisticsUp.questionConsultation.title'),
                            type: "pie",
                            center: ["40%", "45%"],
                            // radius: ["35%", "65%"],
                            radius:'60%',
                            minAngle: 10,//最小角度
                            itemStyle: {
                                // borderRadius: 10,
                                borderColor: "#fff",
                                borderWidth: 3,
                            },
                            data: data,
                            labelLine:{  
                                normal:{  
                                    length:5,   	// 指示线长度
                                },
                            },
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#616161',	// 提示文字颜色
                                        fontSize: 13		// 提示文字大小
                                    } ,
                                    formatter: function (params) {
                                        let percentage = (params.data.percentage*100).toFixed(2)
                                        let name = ''
                                        if(params.data.name.length>5){
                                            name = params.data.name.slice(0,6) + '...'
                                        } else {
                                            name = params.data.name;
                                        }
                                        return `${name}\n${percentage}%`
                                    }
                                },
                            },
                        },
                    ],
                }
                
                qwTypmyChart.setOption(this.option);
                qwTypmyChart.on("click", this.eConsole);
            })
        },
         //增长监听事件
        eConsole(param) {
            if (typeof param.seriesIndex != 'undefined') {
                // 获取总共给分隔的扇形数
                let peiLenght= this.data.length;
                this.data.forEach((item,index) => {
                    this.everyClick(param)
                })
            }
        },
        everyClick(param){    
            this.activeDataTree = JSON.parse(JSON.stringify([param.data]));
            this.activeDataTree[0].id = uuidv4().toString().replaceAll("-", "");
            this.activeDataTree[0].percentage = (param.data.percentage*100).toFixed(2);
            this.activeDataTree[0].children = [];
            for (let key in this.allDataTree) {
                if (Object.hasOwnProperty.call(this.allDataTree, key)) {
                    let element = this.allDataTree[key];
                    if(key == param.data.name){
                        element.forEach((item,index) => {
                            this.activeDataTree[0].children.push({
                                name:item.intentName,
                                percentage:(item.proportion*100).toFixed(2),
                                value:item.hits,
                                id:uuidv4().toString().replaceAll("-", "")
                            });
                        })
                    }
                }
            }
            this.drewerType = 'questionDetail'
            this.visible = true;
        }
    }
}
</script>

<style lang="less" scoped>
.questionConsultation{
    height: 100%;
    .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        span{
            flex: 0.9 0.9 0;
        }

        .help{
            flex: 0.1 0.1 0;
        }
        /deep/ .el-button{
            //width: 116px;
            height: 29px;
            background: #FFFFFF;
            border: 1px solid #E0E6F7;
            border-radius: 5px;
            padding: 0 6px;
            font-size: 14px;
            color: #616161;
            .iconfont{
                font-size: 14px;
                color: #616161;
                margin-right: 4px;
            }
        }
    }
    #qwTypeEcharts1{
        height: 280px;
        margin: 0 auto;
    }
    .legend-box{
        height: 100%;
    }
}

</style>