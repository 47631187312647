<template>
    <div>
        <div class="arc-type-num-box arc-type-num-up" v-if="data.ratio === '0.00%'">
            <span class="arc-type-num zero">--</span>
        </div>
        <template v-else>
            <div class="arc-type-num-box arc-type-num-up" v-if="(!data.ratio && data.ratio == 0) || data.ratioType !== '-'">
                <div class="arc-type-icon-up iconfont guoran-shengjiangjiantou"></div>
                <span class="arc-type-num">{{data.ratio}}</span>
            </div>
            <div class="arc-type-num-box arc-type-num-down" v-else>
                <div class="arc-type-icon-down iconfont guoran-shengjiangjiantou"></div>
                <span class="arc-type-num"> {{data.ratio.substr(1,data.ratio.length)}}</span>
            </div>
        </template>
    </div>
   
</template>

<script>
export default {
    data(){
        return {

        }
    },
    props:{
        data:{
            type:Object,
            default(){
                return {}
            }
        }
    }
}
</script>

<style lang="less" scoped>
.arc-type-num-box {
    height: 16px;
    line-height: 16px;
    background: #F5F7FB;
    border-radius: 8px;
    padding: 0 3px;
    margin-left: 4px;
    font-size: 12px;
    display: flex;
    .arc-type-icon-up {
        color: #FE5965;
    }
    .iconfont {
        font-size: 12px;
    }
    .arc-type-icon-down {
        color: #09DDD5;
        transform: rotate(180deg);
    }
    .arc-type-num{
        margin-left: 3px;
        color: #666666;
        &.zero{
            padding:0 4px;
        }
    }
}

</style>