<template>
    <div class="answerFeedback">
        <div class="title with-handle-title">
            <span>
                {{$t('statisticsUp.feedbackTitle')}}
                <el-tooltip class="item" effect="dark" :content="$t('statisticsUp.feedbackTip')" placement="top-start">
                    <em class="el-icon-warning-outline"></em>
                </el-tooltip>
            </span>
            <el-button v-show="activeTypeIsBot" icon="iconfont guoran-tongyichicun-kaiqi" @click="goToTrainList('feedbackNote')">{{$t('statisticsUp.toOptimize')}}</el-button>
        </div>
        <div v-loading="loading" id="feedbackEcharts" v-show="!isEmipty"></div>
        <div class="legend-box" v-show="isEmipty">
            <no-data-echarts></no-data-echarts>
        </div>
    </div>
</template>

<script>
import NoDataEcharts from './noDataEcharts.vue'
export default {
    props:{
        data:{
            type:Array,
            default(){
                return []
            }
        },
        loading:{
            type:Boolean,
            default:true
        },
        isEmipty:{
            type:Boolean,
            default:false
        },
        activeTypeIsBot:{
            type:Boolean,
            default:false
        }
    },
    components:{
        NoDataEcharts
    },
    watch:{
        data:{
            handler(n){
                if(!this.loading){
                    this.iniEchart(n);
                }
            },
            deep:true,
            immediate:true
        },
    },
    methods:{
        goToTrainList(){
            this.$emit('goToTrainList','feedbackNote')
        },
        // 答案反馈占比
        iniEchart(data) {
            // console.log(data,'答案反馈占比');
            this.$nextTick(() => {
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.$echarts.init(
                    document.getElementById("feedbackEcharts")
                );
                // 绘制图表
                myChart.setOption({
                   
                    title: {
                        text: "",
                        subtext: "",
                        left: "center",
                    },
                    tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)",
                    },
                    legend: {
                        orient: 'vertical',  // 图例竖向排列
                        icon:"path://M512 0C229.376 0 0 228.864 0 512s228.864 512 512 512 512-228.864 512-512S795.136 0 512 0z m0 765.952c-140.288 0-253.952-113.664-253.952-253.952S371.712 258.048 512 258.048s253.952 113.664 253.952 253.952c0.512 140.288-113.664 253.952-253.952 253.952z",
                        align: 'left',
                        x:'right',      //可设定图例在左、右、居中
                        y:'center',     //可设定图例在上、下、居中
                        padding:[0,30,0,30],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                        // formatter: function (params) {
                        //     let name = ''
                        //     if(params.length>5){
                        //         name = params.slice(0,6) + '...'
                        //     } else {
                        //         name = params;
                        //     }
                        //     return name
                        // }
                    },
                    color: ['#FF9555','#458FFF'],
                    series: [
                        {
                            name: this.$t('statisticsUp.feedbackTitle'),
                            type: "pie",
                            center: ["50%", "50%"],
                            radius: ["35%", "65%"],
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: "#fff",
                                borderWidth: 15,
                            },
                            data: data,
                            labelLine:{  
                                normal:{  
                                    length:10,   	// 指示线长度
                                },
                            },
                            label: {
                                normal: {
                                    textStyle: {
                                        color: '#616161',	// 提示文字颜色
                                        fontSize: 13		// 提示文字大小
                                    } ,
                                    // formatter: function (params) {
                                    //     let percentage = (params.data.percentage*100).toFixed(2);
                                    //     let name = ''
                                    //     if(params.data.name.length>5){
                                    //         name = params.data.name.slice(0,6) + '...'
                                    //     } else {
                                    //         name = params.data.name;
                                    //     }
                                    //     return `${name}\n${percentage}%`
                                    // }
                                    
                                },
                            },
                        },
                    ],
                });
            })
        },
    }
}
</script>

<style lang="less" scoped>
.answerFeedback{
    height: 100%;
    .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        span{
            flex: 0.9 0.9 0;
        }

        .help{
            flex: 0.1 0.1 0;
        }
        /deep/ .el-button{
            width: 78px;
            height: 29px;
            background: #FFFFFF;
            border: 1px solid #E0E6F7;
            border-radius: 5px;
            padding: 0;
            font-size: 14px;
            color: #616161;
            .iconfont{
                font-size: 14px;
                color: #616161;
                margin-right: 4px;
            }
        }
    }
    #feedbackEcharts{
        height: 280px;
        margin: 0 auto;
    }
    .legend-box{
        height: 100%;
    }
}

</style>