<template>
    <div id="advisoryquestions">
        <div class="asksta-simple-title">
            <div class="ast-left" :style="$i18n.locale === 'en' ? 'width:250px' : ''">
                <span class="ast-left-name">{{$t('statisticsUp.advisoryQuestions.title')}}</span>
                <span class="ast-left-icon">
                    <el-tooltip class="item" effect="dark" placement="top-start">
                        <div slot="content">
                            <div style="font-weight:700;">{{$t('statisticsUp.questionNumber')}}：</div>
                            <div>{{$t('statisticsUp.advisoryQuestions.tip1')}}</div>
                            <div class="static-empty-dom"></div>
                            <div style="font-weight:700;">{{$t('statisticsUp.recognitionRate')}}：</div>
                            <div>{{$t('statisticsUp.advisoryQuestions.tip2')}}</div>
                            <div>{{$t('statisticsUp.advisoryQuestions.tip3')}}</div>
                        </div>
                        <i class="el-icon-warning-outline"></i>
                    </el-tooltip>
                </span>
            </div>
            <div class="ast-right"  v-if="!(messageData.totalNum === 0 && parseInt(recognitionRateData.totalRate) == 0)">
                <div class="ast-right-cell">
                    <div @click="switchItem(1)" :class="['arc-icon',fActive ? 'color-b' : 'color-gray']"></div>
                    <span @click="switchItem(1)" class="arc-name">{{$t('statisticsUp.questionNumber')}}:</span>
                    <span class="arc-num">{{messageData.totalNum}}</span>
                    <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="messageData"></ration-text>
                </div>
                <div class="ast-right-cell">
                    <div @click="switchItem(2)" :class="[' artificial',tActive ? 'color-o' : 'color-gray']">
                        <div class="left"></div>
                        <div class="cir"></div>
                        <div class="right"></div>
                    </div>
                    <span @click="switchItem(2)" class="arc-name">{{$t('statisticsUp.recognitionRate')}}:</span>
                    <span class="arc-num">{{recognitionRateData.totalRate && recognitionRateData.totalRate != 0 ? (Number(recognitionRateData.totalRate)*100).toFixed(2) + "%" : '0.00%'}}</span>
                    <span class="arc-per-pre">{{$t('statisticsUp.ratio')}}</span>
                    <ration-text :data="recognitionRateData"></ration-text>
                </div>
            </div>
        </div>
        <div style="height: 350px" v-if="messageData.totalNum === 0 && parseInt(recognitionRateData.totalRate) == 0">
            <no-data-echarts></no-data-echarts>
        </div>
        <div class="asksta-receivenum-char" v-show="!(messageData.totalNum === 0 && parseInt(recognitionRateData.totalRate) == 0)">
            <div style="height: 350px" id="asksta-advisory-questions-char"></div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import RationText from './ratio-text.vue'
import NoDataEcharts from './noDataEcharts.vue';
import distinguishEchartsIcon from '../../../assets/images/distinguish-echarts-icon.png'
export default {
    components:{RationText,NoDataEcharts},
    data() {
        return {
            fActive: true,
            tActive: true,
            myChart: null,
            isInitNum:0,
            showArr:[],
            yAxisIndex:1
        }
    },
    props:{
        messageData:{
            type:Object,
            default(){
                return {}
            }
        },
        recognitionRateData:{
            type:Object,
            default(){
                return {}
            }
        },
        loading:{
            type:Boolean,
            default:true
        },
        isInit:{
            type:Boolean,
            default:true
        }
    },
    watch:{
        loading(n){
            if(n){
               this.isInitNum = 0;
            } 
        },
        messageData:{
            handler(n){
                this.isInitNum++
            },
            deep:true
        },
         recognitionRateData:{
            handler(n){
                this.isInitNum++
            },
            deep:true
        },
        isInit:{
            handler(n){
                if(n){
                    this.showArr = [1, 2, 3];
                }
            },
            immediate:true
        },
        isInitNum(n){
            if(n === 2){
                this.iniEchart(this.isInit ? [1, 2] : this.showArr);
            }
        }
    },
    methods: {
        switchItem(index) {
            if (index == 1) {
                if(this.showArr.length === 1 && this.fActive){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.fActive = !this.fActive;
                }
                
            }
            if (index == 2) {
                if(this.showArr.length === 1 && this.tActive){
                    this.$message.warning(this.$t('statisticsUp.keepAtLeastOne'));
                    return false;
                } else {
                    this.tActive = !this.tActive;
                }
            }
            let arr = [];
            if (this.fActive) {
                arr.push(1);
            }
            if (this.tActive) {
                arr.push(2);
            }
            this.showArr = arr;
            this.iniEchart(arr);
        },
        iniEchart(showArr) {
            this.isInitNum = 0;
            
            let data1 = [],data2 = [], date = [];
            this.messageData.data && this.messageData.data.length > 0 && this.messageData.data.forEach((item,index) => {
                date.push(item['日期']);
                data1.push(item['咨询问题数']);
            })
            this.recognitionRateData.data && this.recognitionRateData.data.length > 0 && this.recognitionRateData.data.forEach((item,index) => {
                data2.push(item['识别率']);
            })
            var chartDom = document.getElementById('asksta-advisory-questions-char');
            if (this.myChart != null) {
                this.myChart.clear();
            }
            this.myChart = echarts.init(chartDom);
            if(this.fActive){
                this.yAxisIndex = 1;
            } else {
                this.yAxisIndex = 0;
            }
            let askYAxis = [{
                type: 'value',
                name: '',
                min: 0,
                splitLine: false,
                axisLine: {
                    show: false, //隐藏y轴
                },
                axisTick: {
                    show: false // 不显示坐标轴刻度线
                },
                axisLabel: {
                    formatter: '{value}',
                    color: '#A9B3C6',
                }
            },
            {
                type: 'value',
                name: '',
                min: 0,
                splitLine: false,
                axisLine: {
                    show: false, //隐藏y轴
                },
                axisTick: {
                    show: false // 不显示坐标轴刻度线
                },
                axisLabel: {
                    formatter: function(value){
                        return value*100+'%'
                    },
                    color: '#A9B3C6',
                },
                
            }];
            let askSeries = [
                {
                    show: false,
                    name: this.$t('statisticsUp.questionNumber'),
                    type: 'bar',
                    barMaxWidth: '20px',
                    tooltip: {
                        valueFormatter: function (value) {
                            return value;
                        }
                    },
                    data:data1,
                    itemStyle: {
                        normal: {
                            //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                            barBorderRadius: [50, 50, 50, 50]
                        }
                    },
                },
                
                {
                    name: this.$t('statisticsUp.recognitionRate'),
                    type: 'line',
                    smooth: true,
                    yAxisIndex: this.yAxisIndex,
                    itemStyle: {
                        normal: {
                            lineStyle: {
                            width:4 // 设置线条粗细
                            }
                        }
                    },
                    data:data2,
                    symbol: 'image://' + distinguishEchartsIcon,
                    showAllSymbol: false,
                    symbolSize: 24,
                }
            ]


            let askColor = ['#458FFF', '#69F0A5']
            let yAxis = [], series = [], color = [];

            if (showArr.indexOf(1) != -1 || showArr.indexOf(2) != -1) {
                
                if (showArr.indexOf(1) != -1) {
                    yAxis.push(askYAxis[0]);
                    series.push(askSeries[0])
                    color.push('#458FFF');
                }
                if (showArr.indexOf(2) != -1) {
                    yAxis.push(askYAxis[1]);
                    series.push(askSeries[1])
                    color.push('#69F0A5');
                }
            }
            // 设置网格线
            yAxis[0].splitLine = {
                show:true,
                lineStyle:{
                    color:"#E0E6F7",
                    
                }
            };
            let option = {};
            if(this.messageData.totalNum === 0 && parseInt(this.recognitionRateData.totalRate) == 0){
            //    option = {
            //         title: {
            //             text: '暂无数据',
            //             x: 'center',
            //             y: 'center',
            //             textStyle: {
            //                 fontSize: 16,
            //                 fontWeight: 'normal',
            //                 color:'#A9B3C6'
            //             }
            //         }
            //     }
            } else {
                option = {
                    color: color,
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        },
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                        formatter: function (params) {
                            let str = params[0].name + "<br />";
                            params.forEach((item,index) => {
                                if(item.seriesName === '识别率'){
                                    let num = ((item.data * 100).toFixed(2)) + '%';
                                    str += '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:'+item.color+'"></span>' + item.seriesName + " : " + num + "<br />";
                                } else {
                                    str += '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:'+item.color+'"></span>' + item.seriesName + " : " + item.data + "<br />";
                                }
                                
                            });
                            return str;
                        }
                    },
                    grid: {
                        show: false,
                        top: '20',
                        right: '5%',
                        bottom: '40',
                        left: '5%'
                    },
                    xAxis: {
                        type: 'category',
                        data:date,
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: [...yAxis],
                    series: [...series],
                };
            }
            option && this.myChart.setOption(option);

            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        }
    },
    mounted() {
       
    }
}
</script>
<style lang="less" scoped>
@import "./../../../assets/less/statistics/statisticsCommon.less";
#advisoryquestions {
    height: 424px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    margin-top: 14px;

    .asksta-simple-title {
        .ast-left {
            width: 115px ;
        }

        .ast-right {
            width: calc(100% - 140px);
            justify-content: center;
            align-items: center;
            flex: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;

            .ast-right-cell {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 30px;

                .arc-icon {
                    margin-right: 4px;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    &.color-b {
                        background: #458FFF;
                    }

                    &.color-g {
                        background: #09DDD5;
                    }

                    &.color-o {
                        background: #FF9555;
                    }

                    &.color-gray {
                        background: #A9B3C6;
                    }
                }
                .artificial{
                    
                    display: flex;
                    align-items: center;
                    margin-right: 4px;
                    cursor: pointer;
                    .left,.right{
                        width: 4px;
                        height: 2px;
                        
                    }
                    .cir{
                        width: 8px;
                        height: 8px;
                        border: 2px solid #FF9555;
                        border-radius: 50%;
                    }
                    &.color-o{
                        color: #69F0A5;
                        .left,.right{
                            background-color: #69F0A5;
                        }
                        .cir{
                            border-color: #69F0A5;
                        }
                    }
                    &.color-gray{
                        color: #A9B3C6;
                        .left,.right{
                            background-color: #A9B3C6;
                        }
                        .cir{
                            border-color: #A9B3C6;
                        }
                    }
                }


                .arc-name {
                    cursor: pointer;
                    color: #000000;
                    // font-weight: 600;
                }

                .arc-num {
                    color: #000000;
                    // font-weight: 600;
                    margin-right: 8px;
                    margin-left: 6px;
                }

                .arc-per-pre {
                    color: #A9B3C6;
                }

                .arc-type {
                    margin-left: 6px;
                    margin-right: 4px;

                }
            }
        }
    }
}
</style>