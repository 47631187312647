<template>
    <div id="departmentuse">
        <div class="asksta-simple-title">
            <div class="ast-left">
                <span class="ast-left-name">部门使用情况</span>
                <!-- <span class="ast-left-icon">
                    <el-tooltip class="item" effect="dark" content="选择的统计时间内，与机器人有过对话的用户数。" placement="top-start">
                        <em class="el-icon-warning-outline"></em>
                    </el-tooltip>
                </span> -->
            </div>
            <div class="ast-right" v-show="!isNoData">
                <!-- <div class="ast-right-list" > -->
                    <div class="ast-right-top">
                        <div class="ast-right-cell">
                            <span @click="switchItem(1)" :class="['arc-icon',topActive1 ? 'color-b-1' : 'color-gray']">
                            </span>
                            <span @click="switchItem(1)" class="arc-name">
                                咨询人数
                            </span>
                        </div>
                        <div class="ast-right-cell">
                            <span @click="switchItem(2)" :class="['arc-icon',topActive2 ? 'color-b-2' : 'color-gray']">
                            </span>
                            <span @click="switchItem(2)" class="arc-name">
                                咨询会话数
                            </span>
                        </div>
                        <div class="ast-right-cell">
                            <span @click="switchItem(3)" :class="['arc-icon',topActive3 ? 'color-b-3' : 'color-gray']">
                            </span>
                            <span @click="switchItem(3)" class="arc-name">
                                机器人独立解决会话数
                            </span>
                        </div>
                        <div class="ast-right-cell" v-if="transWorkorderIsShow">
                            <span @click="switchItem(4)" :class="['arc-icon',topActive4 ? 'color-b-4' : 'color-gray']">
                            </span>
                            <span @click="switchItem(4)" class="arc-name">
                                转工单解决会话数
                            </span>
                        </div>
                        <div class="ast-right-cell" v-if="transImIsShow">
                            <span @click="switchItem(5)" :class="['arc-icon',topActive5 ? 'color-b-5' : 'color-gray']">
                            </span>
                            <span @click="switchItem(5)" class="arc-name">
                                转IM人工解决会话数
                            </span>
                        </div>
                    </div>
                    <div class="ast-right-bottom">
                        <div class="ast-right-cell">
                            <span @click="switchItem(6)" :class="['arc-icon',bottomActive1 ? 'color-p-1' : 'color-gray']">
                            </span>
                            <span @click="switchItem(6)" class="arc-name">
                                咨询问题数
                            </span>
                        </div>
                        <div class="ast-right-cell">
                            <span @click="switchItem(7)" :class="['arc-icon',bottomActive2 ? 'color-p-2' : 'color-gray']">
                            </span>
                            <span @click="switchItem(7)" class="arc-name">
                                机器人独立解决问题数
                            </span>
                        </div>
                        <div class="ast-right-cell" v-if="transWorkorderIsShow">
                            <span @click="switchItem(8)" :class="['arc-icon',bottomActive3 ? 'color-p-3' : 'color-gray']">
                            </span>
                            <span @click="switchItem(8)" class="arc-name">
                                转工单解决问题数
                            </span>
                        </div>
                        <div class="ast-right-cell" v-if="transImIsShow">
                            <span @click="switchItem(9)" :class="['arc-icon',bottomActive4 ? 'color-p-4' : 'color-gray']">
                            </span>
                            <span @click="switchItem(9)" class="arc-name">
                                转IM人工解决问题数
                            </span>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
            <div class="ast-right-hadnle" @click="setDepartment">
                <i class="iconfont guoran-a-24-06"></i> <span>设置部门</span>
            </div>
        </div>
        <div class="asksta-receivenum-char" v-show="!isNoData">
            <div style="height: 720px" id="asksta-departmentuse-char"></div>
        </div>
        <div style="height: 720px" v-if="isNoData">
            <no-data-echarts></no-data-echarts>
        </div>
        <popup @closeEvent="setDepartmentPopup = false" v-if="setDepartmentPopup" class="set-department-popup">
            <div slot="popup-name">设置部门</div>
            <div slot="popup-con" class="member-contanier">
                <div class="chosoe-department-btn" @click="openChooseDepart">选择部门</div>
                <div class="set-department-content" v-if="departmentList.length > 0">
                    <div
                        :class="['item','item' + index]"
                        v-for="(item, index) in departmentList"
                        :key="item.id"
                        :data-id="JSON.stringify(item)"
                    >
                       <span class="left">
                            <em
                                v-if="departmentList.length > 1"
                                class="iconfont guoran-tongyichicun-16-10-paixu"
                                @mouseenter="resortOptions(item,index)"
                            ></em>
                            <!-- 0:部门，1:员工，2:上下游部门，3:上下游成员 -->
                                <span class="data-label" v-if="item.isWeWorkThirdData ||
                                                        item.isWeWorkThirdData ||
                                                        (item.originData&&
                                                        item.originData.wechatBotDTO&&
                                                        item.originData.wechatBotDTO.bindType===0&&
                                                        item.originData.wechatBotDTO.wechatType===0)">
                                    <open-data :type="'departmentName'" :openid="item.label"></open-data>
                                </span>
                                <span v-else class="data-label">{{ item.label }}</span>
                        </span>
                        <span
                            v-if="departmentList.length > 1"
                            class="reg-handle-out">
                            <em
                                @click="handleDel(item, index)"
                                class="guoran-tongyichicun-16-09-shanchu2 iconfont"
                            ></em>
                        </span>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="setDepartmentPopup = false" plain  class="cancel-btn">取 消</el-button>
                <el-button type="primary" @click="saveChooseDepart"  class="confirm-btn">确定</el-button>
            </div>
        </popup>
        <popup @closeEvent="chooseDepartmentPopup = false" v-if="chooseDepartmentPopup" class="choose-consultant-popup">
            <div slot="popup-name">选择部门</div>
            <div slot="popup-con" class="member-contanier">
                <staffCustomer 
                    :dataType="'staff'"
                    :chooseType="'dept'"
                    :isMultiple="true" 
                    :selectClient="selectClient"
                    @onNodeClick="onNodeClick" 
                    :clientValue="clientValue" 
                    :memberInfo4Client="memberInfo4Client"
                    ref="staffCustomer"></staffCustomer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="chooseDepartmentPopup = false" plain  class="cancel-btn">取 消</el-button>
                <el-button type="primary" @click="preserStaff" class="confirm-btn">确定</el-button>
            </div>
        </popup>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import OpenData from "../../../components/openData.vue";
import Popup from '../../../components/popup.vue';
import staffCustomer from '../../../components/staffCustomer.vue';
import Sortable from "sortablejs";
import NoDataEcharts from './noDataEcharts.vue'
export default {
    data() {
        return {
            myChart: null,
            fActive: true,
            showArr:[],
            topActive1: true,
            topActive2: true,
            topActive3: true,
            topActive4: true,
            topActive5: true,
            bottomActive1: true,
            bottomActive2: true,
            bottomActive3: true,
            bottomActive4: true,
            chooseDepartmentPopup:false,
            setDepartmentPopup:false,
            departmentList:[],
            selectClient: [],
            memberInfo4Client: {
                clientInfo: [], // 选中数据
            },
            checkedList:[],
            isNoData:false,
            initCheckedList:[],
            departmentListCopy:[]
        }
    },
    components:{OpenData,Popup,staffCustomer,NoDataEcharts},
    props:{
        departmentUseData:{
            type:Object,
            default(){
                return {}
            }
        },
        loading:{
            type:Boolean,
            default:true
        },
        activeScopeType:{
            type:String,
            default:''
        },
        activeScopeValue:{
            type:String,
            default:''
        },
        isInit:{
            type:Boolean,
            default:true
        },
        transWorkorderIsShow:{
            type:Boolean,
            default:true
        },
        transImIsShow:{
            type:Boolean,
            default:true
        }
    },
    watch:{
        isInit:{
            handler(n){
                if(n){
                   
                    // this.showArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
                }
            },
            immediate:true
        },
        departmentUseData:{
            handler(n){
                if(!this.loading){
                    this.isNoData = false;
                    let list = [1,2,3,6,7];
                    if(this.transWorkorderIsShow){
                        this.topActive4 = true
                        this.bottomActive3 = true
                        list = [...list,...[4,8]]
                    } else {
                        this.topActive4 = false
                        this.bottomActive3 = false
                    }
                    if(this.transImIsShow){
                        this.topActive5 = true
                        this.bottomActive4 = true
                        list = [...list,...[5,9]]
                    } else {
                        this.topActive5 = false
                        this.bottomActive4 = false
                    }
                    this.showArr = list;
                    this.handlerEcharts(this.showArr);
                }
            },
            deep:true
        },
    },
    mounted(){
       
    },
    methods: {
        formatterActiveScopeValue(activeScopeValue) {
            return activeScopeValue.split("__")[0];
        },
        // 设置部门
        setDepartment(){
            let scope = this.formatterActiveScopeValue(this.activeScopeValue)
            this.FetchGet(
                this.requestUrl.statistics.getDefaultData + scope,
                { scopeType: this.activeScopeType }
            ).then((res) => {
                if (res.code === "0") {
                    this.departmentList = res.data.departUseRankConfigs || [];
                    this.departmentListCopy = res.data.departUseRankConfigs || []
                    this.memberInfo4Client.clientInfo = this.departmentList;
                    this.setDepartmentPopup = true;
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });

        },

        // 规则排序
        resortOptions(item,index) {
            let box = document.querySelector(".set-department-content");
            let _this = this;
             var Sortables = new Sortable(box, {
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)",
                handle: ".guoran-tongyichicun-16-10-paixu",
                group: 'shared',
                forceFallback: true,
                onUpdate: function (event) {
                    //修改items数据顺序
                    let newIndex = event.newIndex,
                        oldIndex = event.oldIndex,
                        $li = box.children[newIndex],
                        $oldLi = box.children[oldIndex];
                    // 先删除移动的节点
                    box.removeChild($li);
                    // 再插入移动的节点到原有节点，还原了移动的操作
                    if (newIndex > oldIndex) {
                        box.insertBefore($li, $oldLi);
                    } else {
                        box.insertBefore($li, $oldLi.nextSibling);
                    }
                     // 更新items数组
                    let items = _this.departmentList.splice(oldIndex, 1);
                    _this.departmentList.splice(newIndex, 0, items[0]);
                },
            });
           this.departmentList = _this.departmentList;
        },
        handleDel(item,index){
            this.departmentList.splice(index,1)
        },
        // 保存
        saveChooseDepart(){
            let scope = this.formatterActiveScopeValue(this.activeScopeValue)
            let departUseRankConfigs = [];
            this.departmentList.forEach((item,index) => {
                // departUseRankConfigs.push({
                //     departId:item.departId,
                //     departName:item.departName,
                //     wechatType:item.wechatType
                // })
            })
            this.FetchPost(
                this.requestUrl.statistics.getDefaultData + scope,
                { 
                    scopeType: this.activeScopeType,
                    departUseRankConfigs:this.departmentList
                }
            ).then((res) => {
                if (res.code === "0") {
                    this.setDepartmentPopup = false;
                    this.$emit('refeshDepart')
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
        },
        openChooseDepart(){
            this.chooseDepartmentPopup = true;
        },
        preserStaff(){
            this.departmentList = [...this.departmentListCopy,...this.checkedList];
            this.chooseDepartmentPopup = false;
        },
        onNodeClick(node, type, memberInfo) {
            if (node.checked) {
                this.checkedList.push(node);
            } else {
                this.checkedList.forEach((v, i) => {
                    if (node.id == v.id) {
                        this.checkedList.splice(i, 1)
                    }
                })
            }
            this.departmentListCopy.forEach((item,index) => {
                if(!node.checked && node.id == item.id){
                    this.departmentListCopy.splice(index,1)
                }
            })
            this.$refs.staffCustomer.checkMemberList = []
            this.checkedList = this.handlerArr(this.checkedList)
            // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                };
            };
            return result;
        },

        switchItem(index) {
            if (index == 1) {
                if(this.showArr.length === 1 && this.topActive1){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.topActive1 = !this.topActive1;
                }
                
            }
            if (index == 2) {
                if(this.showArr.length === 1 && this.topActive2){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.topActive2 = !this.topActive2;
                }
            }
            if (index == 3) {
                if(this.showArr.length === 1 && this.topActive3){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.topActive3 = !this.topActive3;
                }
            }
            if (index == 4) {
                if(this.showArr.length === 1 && this.topActive4){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.topActive4 = !this.topActive4;
                }
            }
            if (index == 5) {
                if(this.showArr.length === 1 && this.topActive4){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.topActive5 = !this.topActive5;
                }
            }
            if (index == 6) {
                if(this.showArr.length === 1 && this.bottomActive1){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.bottomActive1 = !this.bottomActive1;
                }
            }
            if (index == 7) {
                if(this.showArr.length === 1 && this.bottomActive2){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.bottomActive2 = !this.bottomActive2;
                }
            }
            if (index == 8) {
                if(this.showArr.length === 1 && this.bottomActive3){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.bottomActive3 = !this.bottomActive3;
                }
            }
            if (index == 9) {
                if(this.showArr.length === 1 && this.bottomActive4){
                    this.$message.warning('至少保留一个');
                    return false;
                } else {
                    this.bottomActive4 = !this.bottomActive4;
                }
            }
            
            
            let arr = [];
            if (this.topActive1) {
                arr.push(1);
            }
            if (this.topActive2) {
                arr.push(2);
            }
            if (this.topActive3) {
                arr.push(3);
            }
            if (this.topActive4) {
                arr.push(4);
            }
             if (this.topActive5) {
                arr.push(5);
            }
            if (this.bottomActive1) {
                arr.push(6);
            }
            if (this.bottomActive2) {
                arr.push(7);
            }
            if (this.bottomActive3) {
                arr.push(8);
            }
            if (this.bottomActive4) {
                arr.push(9);
            }
            this.showArr = arr;
            this.handlerEcharts(arr);
        },
        handlerEcharts(showArr) {
            let isWechat = false;
            let yData = [],yData2 = [],xData = [], left1 = [],left2 = [],left3 = [],left4 = [],left5 = [],right1 = [],right2 = [],right3 = [],right4 = [];
            if(this.departmentUseData.sourceData && this.departmentUseData.sourceData.departUseSituationDtos && this.departmentUseData.sourceData.departUseSituationDtos.length > 0){
                this.departmentUseData.sourceData.departUseSituationDtos.forEach((item,index) => {
                    if(item.details && item.details.length > 0){
                        item.details.forEach((detailsItem,detailsIndex) => {
                            // if(detailsItem.num > 0){
                                xData.push(detailsItem.num);
                                if(detailsItem.type === 'CONSUL_NUM'){
                                    left1.push({
                                        value:'-'+detailsItem.num
                                    })
                                } else if(detailsItem.type === 'SESSION'){
                                    left2.push({
                                        value:'-'+detailsItem.num
                                    })
                                } else if(detailsItem.type === 'BOT_SOLVE_SESSION'){
                                    left3.push({
                                        value:'-'+detailsItem.num
                                    })
                                } else if(detailsItem.type === 'WORKORDER_SOLVE_SESSION'){
                                    left4.push({
                                        value:'-'+detailsItem.num
                                    })
                                } else if(detailsItem.type === 'IM_SOLVE_SESSION'){
                                    left5.push({
                                        value:'-'+detailsItem.num
                                    })
                                } else if(detailsItem.type === 'QUESTION'){
                                    right1.push({
                                        value:detailsItem.num
                                    })
                                } else if(detailsItem.type === 'BOT_SOLVE_QUESTION'){
                                    right2.push({
                                        value:detailsItem.num
                                    })
                                } else if(detailsItem.type === 'WORKORDER_SOLVE_QUESTION'){
                                    right3.push({
                                        value:detailsItem.num
                                    })
                                } else if(detailsItem.type === 'IM_SOLVE_QUESTION'){
                                    right4.push({
                                        value:detailsItem.num
                                    })
                                }
                            // }
                        })
                    }
                    if(item.departType === 0){
                        isWechat = true;  
                    }
                    yData.push({
                        departmentName:item.departmentName,
                        departType:item.departType
                    });
                })
            }
            let color = ["#02A3CB", "#00C4BD", "#3CDB83", "#69F0A5", "#BEF691", "#FF581E", "#FF7D4F", "#FFBF6B", "#FFF075"];
            let askSeries = [{
                    name: '咨询人数',
                    type: 'bar',
                    barGap: '0%',
                    barMaxWidth: '20px',
                    itemStyle: {
                        barBorderRadius: [50, 0, 0, 50],
                        color: color[0],
                    },
                    label: {
                        show: true,
                        position: 'inside',
                        formatter(params) {
                            if(Math.abs(params.value) > 0){
                                return Math.abs(params.value)
                            } else {
                                return ''
                            }
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: left1,
                    
                },
                
                {
                    name: '咨询会话数',
                    type: 'bar',
                    barGap: '0%',
                    barMaxWidth: '20px',
                    stack: 'Total1',
                    itemStyle: {
                        barBorderRadius: [50, 0, 0, 50],
                        color: color[1]
                    },
                    label: {
                        show: true,
                        position: 'inside',
                        formatter(params) {
                            if(Math.abs(params.value) > 0){
                                return Math.abs(params.value)
                            } else {
                                return ''
                            }
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: left2
                },
                {
                    name: '机器人独立解决会话数',
                    type: 'bar',
                    barGap: '0%',
                    barMaxWidth: '20px',
                    stack: 'Total2',
                    itemStyle: {
                        barBorderRadius: [50, 0, 0, 50],
                        color: color[2]
                    },
                    label: {
                        show: true,
                        position: 'inside',
                        formatter(params) {
                            if(Math.abs(params.value) > 0){
                                return Math.abs(params.value)
                            } else {
                                return ''
                            }
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: left3
                },
                {
                    name: '转工单解决会话数',
                    type: 'bar',
                    barGap: '0%',
                    stack: 'Total3',
                    barMaxWidth: '20px',
                    itemStyle: {
                        barBorderRadius: [50, 0, 0, 50],
                        color: color[3]
                    },
                    label: {
                        show: true,
                        position: 'inside',
                        formatter(params) {
                            if(Math.abs(params.value) > 0){
                                return Math.abs(params.value)
                            } else {
                                return ''
                            }
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: left4
                },
                {
                    name: '转IM人工解决会话数',
                    type: 'bar',
                    barGap: '0%',
                    barMaxWidth: '20px',

                    stack: 'Total4',
                    itemStyle: {
                        barBorderRadius: [50, 0, 0, 50],
                        color: color[4],
                    },
                    label: {
                        show: true,
                        position: 'inside',
                        formatter(params) {
                            if(Math.abs(params.value) > 0){
                                return Math.abs(params.value)
                            } else {
                                return ''
                            }
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: left5
                },
                {
                    name: '咨询问题数',
                    type: 'bar',
                    barMaxWidth: '20px',
                    barGap: '0%',
                    itemStyle: {
                        barBorderRadius: [0, 50, 50, 0],
                        color: color[5]
                    },
                    stack: 'Total1',
                    label: {
                        show: true,
                        position: 'inside',
                        formatter(params) {
                            if(Math.abs(params.value) > 0){
                                return Math.abs(params.value)
                            } else {
                                return ''
                            }
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: right1
                },
                {
                    name: '机器人独立解决问题数',
                    type: 'bar',
                    barMaxWidth: '20px',
                    barGap: '0%',
                    itemStyle: {
                        barBorderRadius: [0, 50, 50, 0],
                        color: color[6],
                        formatter(params) {
                            if(Math.abs(params.value) > 0){
                                return Math.abs(params.value)
                            } else {
                                return ''
                            }
                        },
                    },
                    stack: 'Total2',
                    label: {
                        show: true,
                        position: 'inside'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: right2
                },
                {
                    name: '转工单解决问题数',
                    type: 'bar',
                    barMaxWidth: '20px',
                    barGap: '0%',
                    itemStyle: {
                        barBorderRadius: [0, 50, 50, 0],
                        color: color[7],
                        formatter(params) {
                            if(Math.abs(params.value) > 0){
                                return Math.abs(params.value)
                            } else {
                                return ''
                            }
                        },
                    },
                    stack: 'Total3',
                    label: {
                        show: true,
                        position: 'inside'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: right3
                },
                {
                    name: '转IM人工解决问题数',
                    type: 'bar',
                    barMaxWidth: '20px',
                    barGap: '0%',
                    itemStyle: {
                        barBorderRadius: [0, 50, 50, 0],
                        color: color[8],
                        formatter(params) {
                            if(Math.abs(params.value) > 0){
                                return Math.abs(params.value)
                            } else {
                                return ''
                            }
                        },
                    },
                    stack: 'Total4',
                    label: {
                        show: true,
                        position: 'inside'
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: right4
                },
            ]
            let series = [];
            if (showArr.indexOf(1) != -1) {
                series.push(askSeries[0])
            }
            if (showArr.indexOf(2) != -1) {
                series.push(askSeries[1])
            }
            if (showArr.indexOf(3) != -1) {
                series.push(askSeries[2])
            }
            if (showArr.indexOf(4) != -1) {
                series.push(askSeries[3])
            }
            if (showArr.indexOf(5) != -1) {
                series.push(askSeries[4])
            }
            if (showArr.indexOf(6) != -1) {
                series.push(askSeries[5])
            }
            if (showArr.indexOf(7) != -1) {
                series.push(askSeries[6])
            }
            if (showArr.indexOf(8) != -1) {
                series.push(askSeries[7])
            }
            if (showArr.indexOf(9) != -1) {
                series.push(askSeries[8])
            }
            // console.log(yData,'yData');
            // 账号属于多个类型时  企微,钉钉
            // setTimeout(() => {
                if(isWechat){
                    WWOpenData.bind(this.$el);
                }
                let items = [],realNameList = [];
                if(yData.length > 0){
                    yData.forEach((item,index) => {
                        if(item.departType === 0){
                            items.push({
                                type:'departmentName',
                                id:item.departmentName,
                                corpid:localStorage.getItem('_corpId')
                            })
                        } else {
                            yData2.push(item.departmentName)
                        } 
                    })
                } else {
                    yData2 = [];
                }
                if(isWechat){
                    if(WWOpenData.initCanvas){
                        WWOpenData.initCanvas()
                    }
                    let result = new Promise((resolve,reject) => {
                        WWOpenData.prefetch({items},(err,data) =>{
                            // console.log(err,data,'WWOpenData-部门');
                            resolve(data);
                            if(data && data.items && data.items.length > 0){
                                data.items.forEach((realNameItem,realNameIndex) => {
                                    realNameList.push(realNameItem.data)
                                })
                            }
                            // console.log(realNameList,'realNameList-部门');

                            if(WWOpenData.initCanvas){
                                WWOpenData.initCanvas()
                            }
                            this.iniEchart(xData,[...realNameList,...yData2],series);
                        })
                    })
                } else {
                    this.iniEchart(xData,yData2,series);
                }
                
            // },2000)
        },
        iniEchart(xData,yData,series){
            // console.log(xData,yData,series,'部门使用情况的数据--');
            var chartDom = document.getElementById('asksta-departmentuse-char');
            if (this.myChart != null) {
                this.myChart.clear();
            }
            this.myChart = echarts.init(chartDom);
            let option = {}
            if(yData.length === 0 || xData.length === 0 ){
                this.isNoData = true;
                
            } else {
                this.isNoData = false;
                option = {
                    tooltip: {
                        show: true,
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                        textStyle:{
                            align:'left'
                        },
                        formatter: function (params) {
                             let str = "";
                            // let str = params[0].name + "<br />";
                            // let str = `<ww-open-data :type="'departmentName'" :openid="${params[0].name}"></ww-open-data>`;
                            params.forEach((item,index) => {
                                let num = Math.abs(item.value)
                                str += '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:'+item.color+'"></span>' + item.seriesName + " : " + num + "<br />";
                            });
                            return str;
                        },
                    },
                    grid: {
                        top: '40',
                        left: '3%',
                        right: '4%',
                        bottom: '60',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        splitLine: false,
                        axisLine: {
                            show: false //隐藏y轴
                        },
                        axisTick: {
                            show: false // 不显示坐标轴刻度线
                        },
                        axisLabel: {
                            show: false,
                            inside: false,
                           
                        },
                        axisPointer: {
                            type: 'shadow'
                        }
                        
                    },
                    yAxis: {
                        type: 'category',
                        data: yData,
                        splitLine: false,
                        axisLine: {
                            show: true, //隐藏y轴
                            lineStyle:{
                                type:'dashed',
                                color:"#616161"
                            }
                        },
                        axisTick: {
                            show: false // 不显示坐标轴刻度线
                        },
                        formatter: (params, k) => {
                            return ["{a" + k + "|" + (k + 1) + "}"].join("\n");
                        }
                    },
                    series: series,
                    dataZoom: [{
                        type: 'slider',
                        show: true,
                        // 设置组件控制的y轴
                        yAxisIndex: 0,
                        left: 0,    
                        // top: 60,
                        // 数据窗口范围的起始百分比。范围是：0 ~ 100。表示 0% ~ 100%
                        // 也可以用 startValue设置起始值
                        start: 0,
                        end: 36,
                        width: 8,
                        // height: 450,
                        // 组件的背景颜色
                        // left: 600, //左边的距离
                        // right: 8,//右边的距离
                        borderRadius: 20,
                        borderColor: "#BFCEEC",
                        fillerColor: '#366aff',//滑动块的颜色
                        backgroundColor: '#BFCEEC',//两边未选中的滑动条区域的颜色
                        // 是否显示detail，即拖拽时候显示详细数值信息
                        showDetail: false,
                        // 控制手柄的尺寸
                        handleSize: 16,
                        // 是否在 dataZoom-silder 组件中显示数据阴影。数据阴影可以简单地反应数据走势。
                        showDataShadow: false,
                        },
                        {
                        type: 'inside',
                        yAxisIndex: [0],
                        start: 1,
                        // end: 36,
                        // 不按任何功能键，鼠标滚轮能触发缩放
                        zoomOnMouseWheel: false,
                        // 不按任何功能键，鼠标移动能触发数据窗口平移
                        moveOnMouseWheel: true
                    }],
                }
            }
            option && this.myChart.setOption(option);
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        },
    },
}
</script>
<style lang="less" scoped>
@import "./../../../assets/less/statistics/statisticsCommon.less";
#departmentuse {
    height: 794px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    margin-top: 14px;
    margin-bottom: 14px;
    overflow: hidden;
    .asksta-simple-title {
        position: relative;
        .ast-right {
            // width: calc(100% - 250px);
            height: 50px;
            display: inline-block;
            margin: 0 auto;
                .ast-right-cell {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 16px;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 5px;
                    width: 160px;
                    .arc-icon {
                        margin-right: 4px;
                        margin-right: 4px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        cursor: pointer;

                        i {
                            font-size: 12px;
                        }

                        &.color-b-1 {
                            background: #02A3CB;
                        }

                        &.color-b-2 {
                            background: #00C4BD;
                        }

                        &.color-b-3 {
                            background: #3CDB83;
                        }

                        &.color-b-4 {
                            background: #69F0A5;
                        }

                        &.color-b-5 {
                            background: #BEF691;
                        }

                        &.color-p-1 {
                            background: #FF581E;
                        }

                        &.color-p-2 {
                            background: #FF7D4F;
                        }

                        &.color-p-3 {
                            background: #FFBF6B;
                        }

                        &.color-p-4 {
                            background: #FFF075;
                        }

                        &.color-gray {
                            background: #A9B3C6;
                        }
                    }

                    .arc-name {
                        cursor: pointer;
                        color: #000000;
                    }

                    .arc-num {
                        color: #000000;
                        font-weight: 600;
                        margin-right: 4px;
                    }

                    .arc-per-pre {
                        color: #A9B3C6;
                    }

                    .arc-type {
                        margin-left: 6px;
                        margin-right: 4px;

                    }

                    .arc-type-num-box {

                        height: 16px;
                        line-height: 16px;
                        background: #F5F7FB;
                        border-radius: 8px;
                        padding: 0 3px;

                        .arc-type-icon-up {
                            color: #FE5965;

                            i {
                                font-size: 12px;
                            }

                        }

                        .arc-type-icon-down {
                            color: #09DDD5;

                            i {
                                font-size: 12px;
                            }

                        }
                    }
                }
            // }

            
        }
        .ast-right-hadnle {
            position: absolute;
            right: 24px;
            top: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 108px;
            height: 29px;
            line-height: 28px;
            background: #FFFFFF;
            border: 1px solid #E0E6F7;
            border-radius: 5px;
            cursor: pointer;
            i {
                font-size: 13px;
            }
            span {
                margin-left: 2px;
            }
        }
    }
    .asksta-receivenum-char{
        padding-left: 50px;
    }
    .set-department-popup{
        .chosoe-department-btn{
            width: 80px;
            height: 33px;
            background: #366AFF;
            border-radius: 5px;
            font-size: 14px;
            color: #FFFFFF;
            text-align: center;
            line-height: 33px;
            cursor: pointer;
        }
        .set-department-content {
            margin: 16px 0;
            padding: 16px 12px;
            border-radius: 5px;
            .item {
                background-color: #fbfcfd;
                margin-bottom: 16px;
                padding: 0 8px;
                height: 52px;
                line-height: 32px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 3px;
                em {
                    margin: 4px;
                    font-size: 18px;
                    color: #366aff;
                    cursor: pointer;
                    margin-right: 10px;
                }
                .data-label{
                    margin-left: 10px;
                }
            }
            .add-em {
                font-size: 35px;
                color: #366aff;
                .cursor {
                    border: 1px solid #d5e0ff;
                    width: 31px;
                    height: 31px;
                    text-align: center;
                    line-height: 31px;
                    border-radius: 50%;
                    display: inline-block;
                }
            }
        }
    }
}
</style>