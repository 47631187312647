<template>
    <div>
        <!-- 问题咨询占比 / 设置问题类型-->
        <popup
            @closeEvent="closeEvent"
            v-if="setTypeDialogVisible"
            class="statistical-classifi-visible"
        >
            <div slot="popup-name">
                {{ $t("statisticsUp.questionConsultation.setQuestionType") }}
            </div>
            <div slot="popup-con">
                <div class="popup-container-self">
                    <div class="list-box">
                        <div class="title-top">
                            <div class="left-title-top">
                                {{ $t("statisticsUp.setTypeDialogTip1") }}
                            </div>
                            <div class="right-title-top">
                                {{ $t("statisticsUp.setTypeDialogTip2") }}
                            </div>
                        </div>
                        <div class="scroll-box">
                            <div
                                class="item-statistical-classifi"
                                v-for="(cell, index) in list"
                                :key="index"
                            >
                                <el-input
                                    v-model.trim="cell.name"
                                    :placeholder="
                                        $t('statisticsUp.quesTypePlaceholder')
                                    "
                                ></el-input>
                                <el-cascader
                                    v-model="cell.intentIds"
                                    :rows="3"
                                    :key="'list_' + index"
                                    :options="intentTree4Radio"
                                    :show-all-levels="false"
                                    :placeholder="
                                        $t('statisticsUp.selectIntent')
                                    "
                                    :props="{
                                        emitPath: false,
                                        label: 'name',
                                        value: 'id',
                                        rows: '3',
                                        multiple: true,
                                        checkStrictly: true,
                                    }"
                                    collapse-tags
                                    filterable
                                >
                                </el-cascader>
                                <i
                                    v-show="list.length > 1"
                                    class="el-icon-delete cursor"
                                    @click="deleteSetType(index)"
                                >
                                </i>
                            </div>
                        </div>
                        <div class="add-classifi-btn">
                            <div
                                class="add-btn guoran-tongyichicun-16-13-xinjian iconfont"
                                @click="addType"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button
                    @click="cancelSaveSetType"
                    plain
                    round
                    class="cancel-btn"
                    >{{ $t("common.cancel") }}</el-button
                >
                <el-button
                    class="confirm-btn"
                    type="primary"
                    @click="saveSetType('')"
                    round
                    style="padding: 0 !important"
                    >{{ $t("common.confirm") }}</el-button
                >
            </div>
        </popup>
    </div>
</template>

<script>
import popup from "../../../components/popup.vue";
import _ from "lodash";
import { nodeListToIntents, convertToStringArray, toIntentTree4Radio, formatSaveConfig } from "./intentTreeCascader";
export default {
    name: "set-type-doalog",
    props: ["scope", "scopeType", "setTypeDialogVisible"],
    components: { popup },
    data() {
        return {
            nodeTree: [],
            intentTree4Radio: [],
            list: [
                {
                    ext: {},
                    intentIds: [],
                    name: "",
                },
            ],
        };
    },
    watch: {
        setTypeDialogVisible(n) {
            console.log(n, "nnnn");
            if (n) {
                this.getIntentTreeByScope();
                this.getSetTypeList();
            }
        },
    },
    methods: {
        closeEvent() {
            this.$emit("update:setTypeDialogVisible", false);
        },
        saveSetType() {
            let arr = [...new Set(this.list.map((item) => item.name))];
            if (arr.length != this.list.length) {
                this.$message.error(this.$t("statisticsUp.nameDuplicationMsg"));
            } else {
                let tampList = _.cloneDeep(this.list);
                this.$emit("saveSetType", formatSaveConfig(tampList, this.nodeTree));
            }
        },
        cancelSaveSetType() {
            this.$emit("cancelSaveSetType");
        },
        // 获取当前机器人下知识库意图列表
        getIntentTreeByScope() {
            this.FetchGet(
                this.requestUrl.statistics.getIntentTreeByScope + this.scope,
                { scopeType: this.scopeType }
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.nodeTree = [...res.data];
                        this.intentTree4Radio = toIntentTree4Radio(res.data);
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取当前机器人设置问题类型列表
        getSetTypeList() {
            this.FetchGet(
                this.requestUrl.statistics.getConfigQuestionType + this.scope,
                {}
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.list = res.data;
                        this.list.forEach((item) => {
                            if (item.nodeList) {
                                let tampIntentIds = convertToStringArray(
                                    item.nodeList
                                );
                                item.intentIds = [...tampIntentIds];
                            } else { // 兼容老版本配置回显
                                item.intentIds.forEach(
                                    (intent, intentIndex) => {
                                        item.intentIds[intentIndex] =
                                            "INTENT~" + intent;
                                    }
                                );
                            }
                        });
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        addType() {
            this.list.push({
                ext: {},
                intentIds: [],
                name: "",
            });
        },
        deleteSetType(index) {
            this.list.splice(index, 1);
        },
    },
};
</script>

<style lang="less" scoped>
.statistical-classifi-visible {
    /deep/ .el-input__inner {
        height: 38px !important;
    }
    /deep/ #popup-assembly {
        width: 796px;
    }
    .popup-container-self {
        max-height: 728px;
        background: #fbfcfd;
        border-radius: 5px;
        width: 100%;
        color: #000000;
        font-size: 14px;
    }
    .list-box {
        margin-bottom: 20px;
        width: 100%;
        .title-top {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            height: 40px;
            background: #f6f8fd;
            border-radius: 5px 5px 0px 0px;
            padding: 0 13px;

            .left-title-top {
                width: 220px;
                font-size: 14px;
                text-align: left;
            }
            .right-title-top {
                width: 65%;
                font-size: 14px;
                text-align: left;
                margin-left: 10px;
            }
        }
        .scroll-box {
            max-height: 350px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .item-statistical-classifi {
            padding: 0 13px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .el-input {
                width: 220px;
                margin-right: 14px;
            }
            /deep/ .el-cascader {
                width: 423px;
                margin: 0 10px;
                .el-cascader__tags {
                    .el-tag {
                        max-width: 80%;
                        height: 24px;
                        background: #edf0f8;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        align-items: center;
                        font-size: 13px;
                        color: #333333;
                        &:first-child {
                            background: #366aff;
                            color: #ffffff;
                            .el-tag__close {
                                font-size: 14px;
                                background-color: transparent;
                                &:hover {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                    .el-cascader__search-input {
                        display: none;
                    }
                }
            }
            .el-icon-delete {
                color: #366aff;
                font-size: 18px;
                margin-left: 9px;
            }
        }
        .add-classifi-btn {
            display: flex;
            justify-content: center;
            .add-btn {
                width: 31px;
                height: 31px;
                background: #ffffff;
                border: 1px solid #d5e0ff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                cursor: pointer;
                color: #366aff;
            }
        }
    }
    .statistical-rank {
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        padding: 14px;
        .statistical-rank-left {
            width: 70px;
            margin-right: 15px;
            white-space: nowrap;
        }
        .statistical-rank-right {
            width: calc(100% - 95px);
            /deep/ .el-input {
                .el-input__inner {
                    line-height: 1px !important;
                }
            }
        }
    }
}
</style>