<template>
    <div id="statistics" :style="
        this.$parent.testDrawerVisibleP || this.$parent.botHeathlyDrawerVisible
            ? 'width:calc(100vw - 500px)'
            : 'width:calc(100vw - 64px)'
    ">
        <page-top-header style="min-width: 1095px">
            <div slot="top-header-left" class="top-header-left">
                <i class="arsenal_icon arsenalzhandianshujutongji "></i>
                <span class="page-name">{{ activePageInfo.headerName }}</span>
               
            </div>
            <div slot="top-header-right" class="top-header-right">
                 <el-select filterable v-model="activeScopeValue" style="width: 185px;margin-right: 12px"
                    @change="selectScopeChange" :placeholder="$t('common.selectPlaceholder')">
                    <el-option-group v-for="group in cOptions" :key="group.label" :label="group.label">
                        <el-option v-for="item in group.options" :key="item.scope + '__' + item.type" :label="item.name"
                            :value="item.scope + '__' + item.type">
                        </el-option>
                    </el-option-group>
                </el-select>
                <div class="input-divs " @click="openStaffPopup(item, index)">
                    <div v-if="memberInfo4Client.clientInfo && memberInfo4Client.clientInfo.length > 0"
                        class="one-column-ellipsis">
                        <span class="have-checked-intent" v-for="(item, index) in memberInfo4Client.clientInfo"
                            :key="index">
                            <!-- 0:部门，1:员工，2:上下游部门，3:上下游成员 -->
                            <span class="data-label" v-if="item.selfType === 0 || item.selfType === 1">
                                <span v-if="item.staffType !== 'staff-t'">{{ item.label }}</span>
                                <span v-else>
                                    <open-data :type="item.selfType == 0 ? 'departmentName' : 'userName'"
                                        :openid="item.label"></open-data>
                                </span>
                            </span>
                            <span v-else>{{ item.name }}</span>
                            <span v-if="index + 1 < memberInfo4Client.clientInfo.length">,</span>
                        </span>

                    </div>
                    <div class="placeholder-text" v-else>{{$t('statisticsUp.allConsultants')}}</div>
                    <i class="el-icon-arrow-down"></i>
                </div>
                <div class="dateSelection">
                    <fastChooseDatePicker ref="fastChooseDatePicker" v-model="dateSeleteData.value"
                        @changeDate="datePickerChange" :isSwitch="true" :type.sync="fastChooseDatePickerType"
                        @changeDateType="changeDateType" :timeType="'statistics'"  @chooseTimeType="chooseTimeType"></fastChooseDatePicker>
                    <!-- <el-button size="mini" @click="exportData" type="primary" :loading="dataIsReady == 1">{{
                            exporttext[dataIsReady]
                    }}</el-button> -->
                </div>
                <div class="export-btn" @click="exportData">
                    <i class="el-icon-loading" v-if="dataIsReady == 1"></i>
                    <i v-else  class="iconfont guoran-write-28"></i>
                    <span v-if="dataIsReady != 1">{{$t('statisticsUp.export')}}</span>
                </div>

            </div>
        </page-top-header>
        <div class="content-main">
            <div class="ask-statistics-row">
                <top-number 
                    :loading="loading_1_top === 1" 
                    :receptionData="receptionData" 
                    :solutionData="solutionData"
                    :sessionData="sessionData"
                    :messageData="messageData"
                    :firstResponseTimeData="firstResponseTimeData"
                    :artificialData="artificialData"
                    :recognitionRateData="recognitionRateData"
                    :avResponseTimeData="avResponseTimeData">
                </top-number>
            </div>
            <!-- 接待情况 -->
            <div style="margin-top: 0px;" class="ask-statistics-row" v-loading="loading_1_top + loading_3 + loading_14 === 3">
                <receive-num
                    :isInit="isInit"
                    :receptionData="receptionData"
                    :sessionData="sessionData"
                    :artificialData="artificialData"
                    :loading="loading_1_top + loading_3 + loading_14 === 3">
                </receive-num>
            </div>
            <!-- 咨询问题数 -->
            <div style="margin-top: 0px;" class="ask-statistics-row" v-loading="loading_2 + loading_3 === 2">
                <advisory-questions 
                    :isInit="isInit"
                    :messageData="messageData" 
                    :recognitionRateData="recognitionRateData"
                    :loading="loading_2 + loading_3 === 2">
                </advisory-questions>
            </div>
            <!-- 会话及问题解决情况 -->
            <div style="margin-top: 0px;" class="ask-statistics-row" v-loading="loading_19 === 1">
                <questions-solution 
                    :isInit="isInit"
                    :transWorkorderIsShow="transWorkorderIsShow"
                    :transImIsShow="transImIsShow"
                    :questionsSolutionData="questionsSolutionData"
                    :loading="loading_19 === 1">
                </questions-solution>
            </div>
            <!-- 转工单类型 -->
            <div style="margin-top: 0px;" class="ask-statistics-row" v-loading="loading_20 === 1" v-if="transWorkorderIsShow">
                <trans-workorder
                    :isInit="isInit"
                    :transWorkerOrderData="transWorkerOrderData"
                    :loading="loading_20 === 1">
                </trans-workorder>
            </div>
            <!-- 转IM人工类型 -->
            <div style="margin-top: 0px;" class="ask-statistics-row" v-loading="loading_21 === 1" v-if="transImIsShow">
                <trans-im
                    :isInit="isInit"
                    :transIMData="transIMData"
                    :loading="loading_21">
                </trans-im>
            </div>
            <!-- 问题识别转工单排名 -->
            <div style="margin-top: 0px;" class="ask-statistics-row" v-loading="loading_22 === 1" v-if="transWorkorderIsShow">
                <qustions-trans
                    :isInit="isInit"
                    :qustionsTransData="qustionsTransData"
                    :loading="loading_22">
                </qustions-trans>
            </div>
            <!-- 部门使用情况 -->
            <div style="margin-top: 0px;" class="ask-statistics-row" v-loading="loading_23 === 1">
                <br>
                <!-- <department-use
                    :transWorkorderIsShow="transWorkorderIsShow"
                    :transImIsShow="transImIsShow"
                    :isInit="isInit"
                    :departmentUseData="departmentUseData"
                    :activeScopeType="activeScopeType"
                    :activeScopeValue="activeScopeValue"
                    :loading="loading_23"
                    @refeshDepart="refeshDepart">
                </department-use> -->
            </div>
            <!-- 满意度调查 -->
            <div class="satisfaction" v-if="satisfactionIsShow">
                <div class="satisfaction-content">
                    <satisfaction-investigation
                        :data="satisfactionInvestigationData"
                        :isEmipty="satisfTypeEchartsIsEmpty"
                        :loading="loading_5 === 1">
                    </satisfaction-investigation>
                </div>
                <div class="satisfaction-content satisfaction-content-right satisfaction-content-right1">
                    <no-satisfiedd-reason
                        :data="noSatisfieddReasonData"
                        :isEmipty="noSatisfTypeEchartsIsEmpty"
                        :loading="loading_6 === 1">
                    </no-satisfiedd-reason>
                </div>
            </div>

            <div class="satisfaction">
                <div :class="['satisfaction-content',answerIsShow ? 'show-answer' : 'hidden-answer']" v-loading="loading_11 === 1">
                    <question-consultation
                        :key="questionConsultationNum"
                        :data="questionConsultationData"
                        :isEmipty="qwTypeEchartsIsEmpty"
                        :loading="loading_9 === 1"
                        :allDataTree="questionConsultationDataTree"
                        @setQuestionType="setTypeDialogVisible=true">
                    </question-consultation>
                </div>
                <div class="satisfaction-content satisfaction-content-right" v-loading="loading_11 === 1" v-show="answerIsShow">
                    <answer-feedback 
                        :key="answerFeedbackNum"
                        :data="answerFeedbackData"
                        :isEmipty="feedbackEchartsIsEmpty"
                        :loading="loading_11 === 1">
                    </answer-feedback>
                </div>
            </div>
            
            <div v-loading="loading_18 == 1" id="people-content" class="people-content qa-consult">
                <question-consultation-rank
                    :isInit="isInit"
                    :data="qaConsultechartData"
                    :isEmipty="qaConsulterchartDataEmpty"
                    :loading="loading_18 === 1"
                    :allDataTree="qaConsultechartDataTree"
                    @openQaConsult="openQaConsult">
                    </question-consultation-rank>
                <!-- <div class="title">
                    <span>问题咨询排名</span>
                    <i class="el-icon-s-tools" @click="openQaConsult"></i>
                </div>
                <ve-histogram :key="histogramNum" :data="qaConsultechartData" :data-empty="qaConsulterchartDataEmpty"
                    :settings="qaConsultechartSettings" width="100%" height="400px" :grid="{ right: 30 }"
                    :colors="peopleNumberColors" :extend="consulteExtend"></ve-histogram> -->
            </div>
            <div class="satisfaction-list">
                <div class="satisfaction-content-list">
                    <div class="title with-handle-title">
                        <span>
                            {{$t('statisticsUp.intentionalConsultation')}}TOP30
                            <el-tooltip class="item" effect="dark" :content="$t('statisticsUp.intentionalConsultationTip')" placement="top-start">
                                <em class="el-icon-warning-outline"></em>
                            </el-tooltip>
                        </span>
                        <div  @click="lookMore" v-if="intentTopTen.length > 10" class="operation-btn">
                            <i class="iconfont guoran-tongyichicun-dianjichakantuli"></i>
                            <span>{{$t('statisticsUp.viewMore')}}</span>
                        </div>
                    </div>
                    <TopTen :tableData="intentTopTen" :loading="loading_10_left == 1" tableType="intent" :intentTopTenQuestion="intentTopTenQuestion"/>
                </div>
                <div class="satisfaction-content-list satisfaction-content-right">
                    <div class="title with-handle-title">
                        <span>
                            {{$t('statisticsUp.unrecognizedIssues')}}TOP30
                            <el-tooltip class="item" effect="dark" :content="$t('statisticsUp.unrecognizedIssuesTip')" placement="top-start">
                                <em class="el-icon-warning-outline"></em>
                            </el-tooltip>
                        </span>
                        <div  v-if="activeTypeIsBot" @click="goToTrainList('question')" class="operation-btn">
                            <i class="iconfont guoran-tongyichicun-biaozhu"></i>
                            <span>{{$t('statisticsUp.toAnnotate')}}</span>
                        </div>
                    </div>
                    <TopTen :tableData="qwTopTen" :loading="loading_10_right == 1" tableType="qw" />
                </div>
            </div>
            <div v-show="showMap" v-loading="loading_13" class="">
                <Area 
                    :scope="formatterActiveScopeValue(activeScopeValue)" 
                    :areaList="areaList"
                    :scopeType="activeScopeType" 
                    @reload13="reload13" 
                    ref="areaComponent" 
                    :isWechat="isWechat"/>
            </div>
            <div class="" style="height: 100px"></div>
        </div>
        <!-- 设置问题类型 -->
        <setTypeDialog
            :setTypeDialogVisible.sync="setTypeDialogVisible"
            :scope="formatterActiveScopeValue(activeScopeValue)" 
            :scopeType="activeScopeType"
            @cancelSaveSetType="cancelSaveSetType" 
            @saveSetType="saveSetType">
        </setTypeDialog>
        <!-- <el-dialog title="设置问题类型" :visible.sync="setTypeDialogVisible" width="600px">
            <div v-if="setTypeDialogVisible">
                <SetType :scope="formatterActiveScopeValue(activeScopeValue)" :scopeType="activeScopeType"
                    @cancelSaveSetType="cancelSaveSetType" @saveSetType="saveSetType" />
            </div>
        </el-dialog> -->

        
        <!-- 选择咨询者 -->
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-consultant-popup">
            <div slot="popup-name">{{$t('statisticsUp.dialogMemberTitle')}}</div>
            <div slot="popup-con" class="member-contanier">
                <div class="choose-style">
                    <div>{{$t('statisticsUp.dialogMemberTip')}}</div>
                    <div class="radio-box">
                        <el-radio v-model="chooseStyle" label="all" >{{$t('statisticsUp.area.all')}}</el-radio>
                        <el-radio v-model="chooseStyle" label="self" >{{$t('statisticsUp.area.custom')}}</el-radio>
                    </div>
                </div>
                <staffCustomer dataType="all" v-if="chooseStyle === 'self'" :isMultiple="true" :selectClient="selectClient"
                    @onNodeClick="onNodeClick" :memberInfo4Client="memberInfo4Client"
                    ref="staffCustomer"></staffCustomer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="cancelChooseMember" plain class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="preserStaff" class="confirm-btn">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
        <setStatisticalClassifi :activeScopeValue="activeScopeValue" :activeScopeType="activeScopeType"
            :statisticalClassifiVisible.sync="statisticalClassifiVisible"
            @updateSetStatisticalClassifi="updateSetStatisticalClassifi">
        </setStatisticalClassifi>
         <intentQuestionDrewer 
            :visible.sync="visible" 
            :tableData="intentTopTen"
            :drewerType.sync="drewerType"
            title="意图咨询排名">
        </intentQuestionDrewer>
    </div>
</template>
<script>
import TopNumber from "./component/TopNumber.vue";
import ReceiveNum from "./component/ReceiveNum.vue";
import AdvisoryQuestions from "./component/AdvisoryQuestions.vue";
import QuestionsSolution from "./component/QuestionsSolution.vue";
import TransWorkorder from "./component/TransWorkorder.vue";
import TransIm from "./component/TransIm.vue";
import QustionsTrans from "./component/QustionsTrans.vue";
import DepartmentUse from "./component/DepartmentUse.vue";
import answerFeedback from './component/answerFeedback.vue';
import satisfactionInvestigation from './component/satisfiedInvestigation.vue';
import noSatisfieddReason from './component/noSatisfieddReason.vue';
import questionConsultation from './component/questionConsultation.vue';
import questionConsultationRank from  './component/questionConsultationRank.vue';
import intentQuestionDrewer from './component/intentQuestionDrewer.vue'
import OpenData from "../../components/openData.vue";
import pageTopHeader from '../../components/page-top-header.vue'
import VeLine from "v-charts/lib/line.common";
import Histogram from "v-charts/lib/histogram.common";
import Area from "./component/Area.vue";
import SetType from "./component/SetType.vue";
import TopTen from "./component/TopTen.vue";
import "v-charts/lib/style.css";
import fastChooseDatePicker from '../botIndex/component/fastChooseDatePicker.vue'
import Popup from '../../components/popup.vue';
import staffCustomer from '../../components/staffCustomer.vue'
import setStatisticalClassifi from './component/setStatisticalClassifi.vue';
import setTypeDialog from "./component/SetTypeDialog.vue";
export default {
    props: ["testDrawerVisibleP"],
    components: { 
        VeLine, 
        Histogram,
        Area,
        SetType, 
        TopTen,
        pageTopHeader, 
        fastChooseDatePicker,
        Popup,
        staffCustomer, 
        setStatisticalClassifi,
        OpenData, 
        TopNumber, 
        ReceiveNum,
        AdvisoryQuestions, 
        QuestionsSolution, 
        TransWorkorder,
        TransIm,
        QustionsTrans,
        DepartmentUse,
        answerFeedback,
        satisfactionInvestigation,
        noSatisfieddReason,
        questionConsultation,
        questionConsultationRank,
        intentQuestionDrewer,
        setTypeDialog
                
    },
    data() {
        (this.grid = {
            show: true,
            top: "0",
            left: "-22%",
            right: "-13%",
            backgroundColor: "#ffffff",
            borderColor: "#fff",
        }),
            (this.peopleNumbergrid = {
                show: true,
                top: "0",
                left: "0",
                backgroundColor: "#ffffff",
                borderColor: "#fff",
            }),
            (this.peopleNumberxAxis = {
                type: "category",
                show: false,
            });
        this.xAxis = {
            type: "category",
            show: false,
        };
        this.grid = {
            right: 0,
            top: 5,
            left: -24,
        };
        this.yAxis = {
            type: "value",
            show: false,
        };
        this.recognitionRatecolors = ["#fec70a"];
        this.messageNumberColors = ["#a626e8"];
        this.peopleNumberColors = ["#366AFF"];
        this.peoplelargecolors = ["#366AFF", "#ffc922", "#ffc922"];
        this.messagelargecolors = ["#a626e8", "#ffc922", "#ffc922"];
        this.peoplelargechartSettings = {
            showLine: ["满意率"],
            axisSite: { right: ["满意率"] },
            yAxisType: ["KMB", "percent"],
            yAxisName: ["客户数", ""],
        };
        this.messagelargechartSettings = {
            showLine: ["识别率"],
            axisSite: { right: ["识别率"] },
            yAxisType: ["KMB", "percent"],
            yAxisName: ["            客户消息数", "识别率"],
        };
        this.qaConsultechartSettings = {
            showLine: [""],
            axisSite: {},
            yAxisType: [""],
            yAxisName: [""],
        };
        return {
            cOptions: [],
            exporttext: ["数据导出", "生成中..."],
            dataIsReady: 0,
            newStartTime: "",
            newEndTime: "",
            // 新版选择渠道 start
            activeScopeValue: "", // 新版选择渠道scope
            activeScopeType: "", // 新版选择渠道type
            activeScopeId: "",   //新版选择渠道的id
            botScopeValue: "",  //新版机器人渠道scope
            activeTypeIsBot: false, //新版判断渠道是否是机器人
            appTypeImageList: [
                // 新版选择渠道icon list
                "https://static.guoranbot.com/cdn-arsenal/dev/img/qiyechat.b7289a96.png",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/Wechat_128px_1230528.ff737c05.png",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/xiaochengxu.e4adc93b.jpeg",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/dingding.874450b3.jpeg",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/webchat.a051ad70.png",
                "https://static.guoranbot.com/cdn-arsenal/dev/img/Wechat_128px_1230528.ff737c05.png",
            ],
            scopeList: [],
            areaList: [],
            // 新版选择渠道 end

            // loading start
            loading_1_top: 1,
            loading_1_bottom: 1,
            loading_2: 1,
            loading_3: 1,
            loading_m_1: 1,
            loading_m_2: 1,
            loading_5: 1,
            loading_6: 1,
            loading_10_left: 1,
            loading_10_right: 1,
            loading_11: 1,
            loading_9: 1,
            loading_18: 0,
            loading_14:0,
            loading_19:0,
            loading_20:0,
            loading_21:0,
            loading_22:0,
            loading_23:0,
            // top Number
            _totalNum: "empty",
            _totalSessionNum: "empty",
            satisfTypeEchartsIsEmpty: false,
            noSatisfTypeEchartsIsEmpty: false,
            qwTypeEchartsIsEmpty: false,
            feedbackEchartsIsEmpty: false,
            _avResponseSec: 0,
            _firstResponseSec: 0,

            activePageInfo: {
                headerName: this.$t('statisticsUp.headerName'),
            },
            activeTabName: "question",
            loading: true,
            loading2: false,
            dateSeleteData: [{ calue: "" }],
            currentDate: "diy",
            firstResponseTimeEmpty: false,
            firstResponseTime: { respTime: "" },
            averageResponseTimeEmpty: false,
            averageResponseTime: { respTime: "" },
            peopleNumberchartDataEmpty: false,
            peopleNumberchartData: {
                totalNum: "",
                columns: ["日期", "访问用户"],
                rows: [{ 日期: "", 访问用户: "" }],
            },
            messageNumberChartDataEmpty: false,
            messageNumberChartData: {
                totalNum: "",
                columns: ["日期", "客户消息数"],
                rows: [{ 日期: "", 客户消息数: "" }],
            },
            messageNumberChartSettings: {
                area: { show: true },
                label: { show: false },
                labelLine: { show: false },
            },
            recognitionRateChartDataEmpty: false,
            recognitionRateChartData: {
                totalRate: "",
                indicatorName: "",
                columns: ["日期", "识别率"],
                rows: [{ 日期: "", 识别率: null }],
            },
            recognitionRatechartExtend: {
                series: {
                    center: [112, 92],
                    areaStyle: { color: "#fffae2" },
                    lineStyle: { color: "#ffc922" },
                },
            },
            recognitionRateChartSettings: {
                area: { show: true },
                label: { show: false },
                labelLine: { show: false },
            },
            peoplelargechartData: {
                columns: ["日期", "访问用户"],
                rows: [{ 日期: "", 访问用户: "" }],
            },
            messagelargechartDataEmpty: false,
            messagelargechartData: {
                columns: ["日期", "访问用户", "识别率"],
                rows: [{ 日期: "", 访问用户: "", 识别率: "" }],
            },
            satisfactionchartDataEmpty: false,
            satisfactionchartData: {
                columns: ["满意度调查", "访问次数"],
                rows: [
                    { 满意度调查: "满意次数", 访问次数: "" },
                    { 满意度调查: "不满意次数", 访问次数: "" },
                ],
            },
            dissatisfiedchartDataEmpty: false,
            dissatisfiedchartData: {
                columns: ["不满意统计", "访问次数"],
                rows: [{ 不满意统计: "", 访问次数: "" }],
            },
            setTypeDialogVisible: false,
            intentTopTen: [],
            qwTopTen: [],
            showMap: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 30 * 24 * 60 * 60 * 1000 || time.getTime() > Date.now();
                },
            },
            fastChooseDatePickerType: "datetimerange",
            fastChooseDatePickerTypeS: "datetimerange",
            statisticalClassifiVisible: false,
            botId: '',
            dialogMemberTree: false,
            selectClient: [],
            memberInfo4Client: {
                clientInfo: [], // 选中数据
            },
            departmentId: [],
            memberId: [],
            upDownDepartmentId: [],
            upDownMemberId: [],
            chooseStyle: "all",
            chooseStyleOld:"all",
            checkedList: [],
            histogramNum: 1,
            qaConsultechartData: {
                columns: ["分类", "数量"],
                rows: [{ 分类: "", 数量: "" }],
            },
            qaConsulterchartDataEmpty: false,
            consulteExtend: {
                series: {
                    barMaxWidth: 80,
                    type: "bar",
                    grid: {
                        left: '1%',
                        right: '1%',
                        containLabel: true,
                    },
                },
                xAxis: {
                    //设置文本过长超出隐藏...表示
                    axisLabel: {
                        margin: 8,
                        formatter: function (params) {
                            var val = "";
                            if (params.length > 4) {
                                val = params.substr(0, 4) + '...';
                                return val;
                            } else {
                                return params;
                            }
                        }
                    },
                },
            },
            dateType:'DAY',
            exportFlagLoading:false,
            solutionData: {}, // 解决率
            receptionData:{}, // 总接待人数-数据
            sessionData:{}, // 接待会话数 - 数据
            messageData:{}, // 咨询问题/消息数  - 数据
            firstResponseTimeData:{}, // 平均首次响应时长-数据
            artificialData:{}, // 转人工率-数据
            avResponseTimeData:{},// 平均响应时长-数据
            recognitionRateData:{}, // 识别率-数据
            questionsSolutionData:{}, // 会话及问题解决情况-数据
            transWorkerOrderData:{}, //转工单类型-数据 
            transIMData:{}, // 转IM人工类型-数据
            qustionsTransData:{}, // 问题识别转工单排名-数据
            departmentUseData:{}, // 部门使用情况-数据
            answerFeedbackData:[], // 答案反馈占比-数据
            satisfactionInvestigationData:[], // 满意度调查-数据
            noSatisfieddReasonData:[], // 不满意度原因-数据
            questionConsultationData:[], // 问题咨询占比
            questionConsultationDataTree:{}, // 问题咨询占比-问题咨询详情数据
            qaConsultechartDataTree:{}, // 问题咨询排名-问题咨询详情数据
            visible:false,
            drewerType:'',
            intentTopTenQuestion:{},
            isInit:true,
            answerIsShow:true, // 答案反馈组件是否显示
            satisfactionIsShow:true, // 满意度组件是否显示
            isWechat:false, // 是否是企微
            questionConsultationNum:0,
            answerFeedbackNum:0,
            transImIsShow:false,
            transWorkorderIsShow:false,
            transImIsShows:false,
            transWorkorderIsShows:false
        };
    },
    methods: {
        // 获取满意度/答案反馈权限
        getJurisdictionData(){
            // console.log('获取满意度/答案反馈权限');
            this.FetchPost(this.requestUrl.statistics.statisticsViewDisplayControl, {
                scope: this.formatterActiveScopeValue(this.activeScopeValue),
                scopeType: this.activeScopeType,
            }).then((res) => {
                if (res.code === "0") {
                    this.answerIsShow = res.data.feedback;
                    this.satisfactionIsShow = res.data.satisfaction;
                    this.transImIsShows = res.data.transfer;
                    this.transWorkorderIsShows = res.data.form;
                } else {
                    this.$message.error(res.message);
                }
            })
            .catch((err) => {
                this.$message(err);
            });
        },
        cancelChooseMember(){
            this.chooseStyle = this.chooseStyleOld;
            this.dialogMemberTree = false;
        },
        refeshDepart(){
            this.getStatisticsDataNew(this.newStartTime, this.newEndTime, 23, "indicator");
        },
        lookMore(){
            this.drewerType = 'intent';
            this.visible = true;
        },
        chooseTimeType(datas){
            this.dateType = datas;
        },
        changeDateType(type) {
            this.fastChooseDatePickerTypeS = type;
        },
        preserStaff() {
            this.chooseStyleOld = this.chooseStyle;
            if (this.chooseStyle === 'all') {
                this.departmentId = []
                this.memberId = []
                this.upDownDepartmentId = []
                this.upDownMemberId = []
                this.checkedList = []
            }
            this.memberInfo4Client.clientInfo = this.checkedList || [];
            this.dialogMemberTree = false;
            this.getDataCell(this.newStartTime, this.newEndTime);
        },
        openStaffPopup() {
            this.isInit = false;
            this.dialogMemberTree = true;
        },
        onNodeClick(node, type, memberInfo) {
            this.departmentId = []
            this.memberId = []
            this.upDownDepartmentId = []
            this.upDownMemberId = []
            if (node.checked) {
                this.checkedList.push(node);
            } else {
                this.checkedList.forEach((v, i) => {
                    if (node.id == v.id) {
                        this.checkedList.splice(i, 1)
                    }
                })
            }
            this.$refs.staffCustomer.checkMemberList = []
            this.checkedList = this.handlerArr(this.checkedList)
            // type类型 -- 保存接口需要 ,0:部门，1:员工，2:上下游部门，3:上下游成员 数字型字段
            this.checkedList.forEach((item, index) => {
                if (item.selfType === 0) {
                    this.departmentId.push(item.id)
                } else if (item.selfType === 1) {
                    this.memberId.push(item.id)
                } else if (item.selfType === 2) {
                    this.upDownDepartmentId.push(item.id)
                } else if (item.selfType === 3) {
                    this.upDownMemberId.push(item.id)
                }
            })
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                };
            };
            return result;
        },
        openQaConsult() {
            this.statisticalClassifiVisible = true;
        },
        updateSetStatisticalClassifi() {
            this.getStatisticsDataNew(this.newStartTime, this.newEndTime, 18, "indicator");
        },
        formatterActiveScopeValue(activeScopeValue) {
            return activeScopeValue.split("__")[0];
        },
        // 导出数据
        exportData() {
            let href =
                this.requestUrl.statistics.exportStatic +
                this.formatterActiveScopeValue(this.activeScopeValue) +
                "?scopeType=" +
                this.activeScopeType +
                "&startTime=" +
                this.newStartTime +
                "&endTime=" +
                this.newEndTime;
            let params = {
                scope: this.formatterActiveScopeValue(this.activeScopeValue),
                scopeType: this.activeScopeType,
                startTime: this.newStartTime,
                endTime: this.newEndTime,
                dateType:this.dateType,
                mainId:localStorage.getItem('_mainId')
            }
            params = Object.assign(params,this.handlerParams())
            this.dataIsReady = 1;
            this.FetchPost(this.requestUrl.statistics.exportStatic, params)
                .then((res) => {
                    if (res.code === "0") {
                        window.location.href = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.dataIsReady = 0;
                })
                .catch((err) => {
                    this.dataIsReady = 0;
                    this.$message(err);
                });
        },
        handlerParams(){
            let param = {}
            if (this.fastChooseDatePickerType === 'monthrange') {
                if (this.$refs.fastChooseDatePicker) {
                    this.$refs.fastChooseDatePicker.handlerMonthClose();
                }
                if (this.fastChooseDatePickerTypeS === 'monthrange') {
                    param.month = true;
                } else {
                    param.month = false;
                }
            } else {
                param.month = false;
            }
            if (this.chooseStyle === 'all') {
                param.departmentId = [];
                param.memberId = [];
                param.upDownDepartmentId = [];
                param.upDownMemberId = [];
            } else {
                param.departmentId = this.departmentId
                param.memberId = this.memberId
                param.upDownDepartmentId = this.upDownDepartmentId
                param.upDownMemberId = this.upDownMemberId
            }
            return param;
        },
        // 跳转去标注/优化
        goToTrainList(type) {
            this.$router.push("/main/trainList?type=" + type + '&BotId=' + this.activeScopeId + '&scope=' + this.botScopeValue);
        },
        // 修改统计维度
        selectScopeChange(value) {
            this.isInit = false;
            this.activeTypeIsBot = false
            this.activeScopeId = ''
            this.botScopeValue = ""
            this.scopeList.forEach((item) => {
                if (item.scope + "__" + item.type == value) {
                    this.activeScopeType = item.type;
                    this.botId = item.id;
                    // 记录上次选择渠道
                    localStorage.setItem("activeScopeValue", value);
                }
                if (item.type === 'BOT' && item.scope + "__" + item.type == value) {
                    this.activeScopeId = item.id
                    this.activeTypeIsBot = true
                    this.botScopeValue = item.scope
                }
            });
            this.getJurisdictionData();
            this.changeGetData();
        },
        // 获取统计维度列表
        getStatisticsScopes() {
            this.cOptions = [
                {
                    label: this.$t('statisticsUp.websiteAccess'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.wechat'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.weChatMiniProgram'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.weChatOfficialAccount'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.dingDing'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.weChatCustomerService'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.dingDingGroup'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.groupTemplate'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.application'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.dingSingleChatBot'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.bot'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.bot'),
                    options: [],
                },
                {
                    label: this.$t('statisticsUp.feiShu'),
                    options: [],
                },
            ];
            this.FetchGet(this.requestUrl.statistics.getStatisticsScopes).then(
                (res) => {
                    if (res.code === "0") {

                        let tampScopeValue = [];
                        this.scopeList = res.data;
                        this.scopeList.forEach((item) => {
                            tampScopeValue.push(item.scope + "__" + item.type);

                            if (item.type == "APPLET") {
                                this.cOptions[2].options.push(item);
                            } else if (item.type == "BOT") {
                                this.cOptions[10].options.push(item);
                            } else {
                                switch (item.appType) {
                                    case 0:
                                        this.cOptions[1].options.push(item);
                                        break;
                                    case 1:
                                        this.cOptions[3].options.push(item);
                                        break;
                                    case 2:
                                        // this.cOptions[0].options.push(item);
                                        break;
                                    case 3:
                                        this.cOptions[4].options.push(item);
                                        break;
                                    case 4:
                                        this.cOptions[0].options.push(item);
                                        break;
                                    case 5:
                                        this.cOptions[5].options.push(item);
                                        break;
                                    case 6:
                                        this.cOptions[6].options.push(item);
                                        break;
                                    case 8:
                                        this.cOptions[8].options.push(item);
                                        break;
                                    case 9:
                                        this.cOptions[9].options.push(item);
                                        break;
                                    case 10:
                                        this.cOptions[12].options.push(item);
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });

                        // this.activeScopeValue =
                        //     this.scopeList[0].scope +
                        //     "__" +
                        //     this.scopeList[0].type;
                        // this.activeScopeType = this.scopeList[0].type;
                        // this.botId = this.scopeList[0].id
                        let flag = -1; 
                        this.cOptions.forEach((cell, cellIndex) => {
                           
                            if (cell.options.length > 0 && flag === -1) {
                                flag = cellIndex;
                            }
                            cell.options.forEach(item => {

                                // 是否回显上一次选择渠道
                                if (localStorage.getItem("activeScopeValue") == null) {
                                    this.activeScopeValue = this.cOptions[flag].options[0].scope + "__" + this.cOptions[flag].options[0].type;
                                    this.activeScopeType = this.cOptions[flag].options[0].type;
                                    this.botId = this.cOptions[flag].options[0].id;
                                    localStorage.setItem("activeScopeValue", this.activeScopeValue);
                                } else {
                                    // 是否切换了账号或应用
                                    if (tampScopeValue.indexOf(localStorage.getItem("activeScopeValue")) !== -1) {
                                        if (localStorage.getItem("activeScopeValue") === item.scope + "__" + item.type) {
                                            this.activeScopeValue = item.scope + "__" + item.type;
                                            this.activeScopeType = item.type;
                                            this.botId = item.id;
                                        }
                                    } else {
                                        console.log(934);
                                        this.activeScopeValue = this.cOptions[flag].options[0].scope + "__" + this.cOptions[flag].options[0].type;
                                        this.activeScopeType = this.cOptions[flag].options[0].type;
                                        this.botId = this.cOptions[flag].options[0].id;
                                        localStorage.setItem("activeScopeValue", this.activeScopeValue);
                                    }
                                }
                            })
                        })

                        this.getJurisdictionData();
                        this.getDataCell(this.newStartTime, this.newEndTime);

                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        isEmpty(data){
            let num = 0, flag = false;
            data.forEach((item,index) => {
                if(item.value == 0){
                    num++
                }
            })
            if(num === data.length){
               flag = true; 
            }
            return flag
        },
        // 获取统计数据
        /*
            0：接待人数 
            1：咨询问题/消息数
            2：识别率 
            3：平均首次响应时长 
            4：平均响应时长 
            5：满意度
            6：不满意原因 "
            7：接待会话数 
            8：转工单率
            9：咨询问题类型 
            10：意图排行 
            11：答案反馈占比 
            12：未识别问题 
            13：地域分布 
            14：转人工率 
            15：不识别推荐点击排名 
            16：智能引导点击排名"
            17：知识库更新情况 
            18：问题咨询排名 
            19：会话及问题解决情况 
            20：转工单类型
            21：转IM人工类型 
            22：问题识别转工单排名 
            23：部门使用情况
        */
        getStatisticsDataNew(startTime, endTime, indicator, type) {
            // this.messageData = {};
            // this.recognitionRateData = {};
            switch (indicator) {
                case 0:
                    this.loading_1_top = 1;
                    break;
                case 7:
                    this.loading_1_bottom = 1;
                    break;
                case 1:
                    this.loading_2 = 1;
                    break;
                case 2:
                    this.loading_3 = 1;
                    break;
                case 3:
                    this.loading_4_top = 1;
                    break;
                case 4:
                    this.loading_4_bottom = 1;
                    break;
                case "0,7":
                    this.loading_m_1 = 1;
                    break;
                case "1,2":
                    this.loading_m_2 = 1;
                    break;
                case 5:
                    this.loading_5 = 1;
                    break;
                case 6:
                    this.loading_6 = 1;
                    break;
                case 10:
                    this.loading_10_left = 1;
                    break;
                case 9:
                    this.loading_9 = 1;
                    break;
                case 11:
                    this.loading_11 = 1;
                    break;
                case 12:
                    this.loading_10_right = 1;
                    break;
                case 13:
                    this.loading_13 = 1;
                    break;
                case 14:
                    this.loading_14 = 1;
                    break;
                case 18:
                    this.loading_18 = 1;
                    break;
                case 19:
                    this.loading_19 = 1;
                    break;
                case 20:
                    this.loading_20 = 1;
                    break;
                case 21:
                    this.loading_21 = 1;
                    break;
                case 21:
                    this.loading_22 = 1;
                    break;
                case 21:
                    this.loading_23 = 1;
                    break;
                case 24:
                    this.loading_1_top = 1;
                    break;
                default:
                    break;
            }
            let param = {
                scope: this.formatterActiveScopeValue(this.activeScopeValue),
                scopeType: this.activeScopeType,
                startTime: startTime,
                endTime: endTime,
                dateType:this.dateType,
                mainId:localStorage.getItem('_mainId')
            };
            // 单个数据查询
            if (type == "indicator") {
                param.indicator = indicator;
            }
            // 联合数据查询
            if (type == "indicators") {
                param.indicators = indicator;
            }
            param = Object.assign(param,this.handlerParams())
            
            indicator != 23 && this.FetchPost(
                this.requestUrl.statistics.getStatisticsData,
                param
            ).then((res) => {
                if (res.code == "0") {
                    switch (indicator) {
                        case 0:
                            if (res.data != null && res.data != "null") {
                                this.receptionData = res.data;
                                this.receptionData.ratio = res.data.ratio && res.data.ratio !== '0%' ? res.data.ratio : '0.00%'
                                this.receptionData.ratioType = res.data.ratio.charAt(0);
                            } else {
                                this.receptionData = {
                                    totalNum:0,
                                    validRecordNum: 0,
                                    invalidRecordNum: 0,
                                    ratio:'0.00%',
                                    ratioType:"0",
                                    data:[]
                                };
                                // this._totalNum = "empty";
                            }
                            this.loading_1_top = 0;
                            break;
                        case 1:
                            if (res.data != null && res.data != "null") {
                                this.messageData = res.data;
                                this.messageData.ratio = res.data.ratio && res.data.ratio !== '0%' ? res.data.ratio : '0.00%'
                                this.messageData.ratioType = res.data.ratio.charAt(0);
                            } else {
                                this.messageData = {
                                    totalNum:0,
                                    ratio:'0.00%',
                                    ratioType:"0",
                                    noIdentifiedNum: 0,
                                    data:[]
                                }
                            }
                            this.loading_2 = 0;
                            break;
                        case 2:
                            if (res.data != null && res.data != "null") {
                                this.recognitionRateData = res.data;
                                this.recognitionRateData.ratio = res.data.ratio && res.data.ratio !== '0%' ? res.data.ratio : '0.00%'
                                this.recognitionRateData.ratioType = res.data.ratio.charAt(0);
                            } else {
                                this.recognitionRateData = {
                                    totalRate:0,
                                    ratio:'0.00%',
                                    ratioType:"0",
                                    data:[]
                                }
                            }
                            this.loading_3 = 0;
                            break;
                        case 3:
                            if (res.data != null && res.data != "null") {
                                this.firstResponseTimeData = res.data;
                                this.firstResponseTimeData.ratio = res.data.ratio && res.data.ratio !== '0%' ? res.data.ratio : '0.00%'
                                this.firstResponseTimeData.ratioType = res.data.ratio.charAt(0);
                            } else {
                                this.firstResponseTimeData = {
                                    respTime:0,
                                    ratio:'0.00%',
                                    ratioType:"0"
                                }
                            }
                            // this._firstResponseSec = res.data && res.data.respTime ? res.data.respTime : null;
                            // this.loading_4_top = 0;
                            break;
                        case 4:
                            if (res.data != null && res.data != "null") {
                                this.avResponseTimeData = res.data;
                                this.avResponseTimeData.ratio = res.data.ratio && res.data.ratio !== '0%' ? res.data.ratio : '0.00%'
                                this.avResponseTimeData.ratioType = res.data.ratio.charAt(0);
                            } else {
                                this.avResponseTimeData = {
                                    respTime:0,
                                    ratio:'0.00%',
                                    ratioType:"0"
                                }
                            }
                            // this._avResponseSec = res.data && res.data.respTime ? res.data.respTime : null;
                            // this.loading_4_bottom = 0;
                            break;
                        case 5:
                            if (res.data != null && res.data != "null") {
                                let tampSArr = [];
                                res.data.data.forEach((item) => {
                                    if(item.数量 > 0){
                                        tampSArr.push({
                                            name: item.名称,
                                            value: item.数量,
                                            percentage:item.百分比,
                                        });
                                    }
                                });
                               
                                this.satisfactionInvestigationData = tampSArr.reverse();
                                this.satisfTypeEchartsIsEmpty = false;
                                if(tampSArr.length === 0){
                                    this.satisfTypeEchartsIsEmpty = true;
                                    // this.isEmpty(tampSArr)
                                }
                            } else {
                                this.satisfTypeEchartsIsEmpty = true;
                            }
                            this.loading_5 = 0;
                            break;
                        case 6:
                            if (res.data != null && res.data != "null") {
                                let tampNSArr = [];
                                res.data.data.forEach((item) => {
                                    if(item.数量 > 0){
                                        tampNSArr.push({
                                            name: item.名称,
                                            value: item.数量,
                                            percentage:item.百分比,
                                        });
                                    }
                                });
                                this.noSatisfieddReasonData = tampNSArr.reverse();
                                this.noSatisfTypeEchartsIsEmpty = false;
                                if(tampNSArr.length === 0){
                                    this.noSatisfTypeEchartsIsEmpty = true;
                                    // this.isEmpty(tampSArr)
                                }
                            } else {
                                this.noSatisfTypeEchartsIsEmpty = true;
                            }
                            this.loading_6 = 0;
                            break;
                        case 7:
                            if (res.data != null && res.data != "null") {
                                this.sessionData = res.data;
                                this.sessionData.ratio = res.data.ratio && res.data.ratio !== '0%' ? res.data.ratio : '0.00%'
                                this.sessionData.ratioType = res.data.ratio.charAt(0);
                            } else {
                                this.sessionData = {
                                    totalNum:0,
                                    ratio:'0.00%',
                                    ratioType:"0",
                                    data:[]
                                }
                                // this._totalSessionNum = "empty";
                            }
                            this.loading_1_bottom = 0;
                            break;
                        case 9:
                            this.questionConsultationNum++
                            if (res.data != null && res.data != "null") {
                                let tampQwArr = [],num=0;
                                res.data.data.forEach((item) => {
                                    if (item.数量 === 0) {
                                       num++
                                    }
                                    if(item.数量 > 0){
                                        tampQwArr.push({
                                            name: item.名称,
                                            value: item.数量,
                                            percentage:item.百分比,
                                        });
                                    }
                                    
                                });
                                for (let key in res.data.sourceData.intentQuestionRankMap) {
                                    if (Object.hasOwnProperty.call(res.data.sourceData.intentQuestionRankMap, key)) {
                                        let element = res.data.sourceData.intentQuestionRankMap[key];
                                        if(element.length > 0){
                                            this.questionConsultationDataTree[key]=element
                                        }
                                        
                                    }
                                }
                                // this.qwTypeEchartsIsEmpty = tampQwArr.length === num
                                this.qwTypeEchartsIsEmpty = tampQwArr.length === 0 ? true : false;
                                this.questionConsultationData = tampQwArr;
                                this.loading_9 = 0;
                            } else {
                                this.qwTypeEchartsIsEmpty = true;
                            }
                            this.loading_9 = 0;
                            break;
                        case 10:
                            if (res.data != null && res.data != "null") {
                                this.intentTopTen = res.data.data;
                                this.intentTopTenQuestion = res.data.sourceData.intentQuestionRankList
                            } else {
                                this.intentTopTen = [];
                                this.intentTopTenQuestion = {};
                            }
                            this.loading_10_left = 0;
                            break;
                        case 11:
                            this.answerFeedbackNum++
                            if (res.data != null && res.data != "null") {
                                let tampFeedbackArr = [];
                                res.data.data.forEach((item) => {
                                    if(item.数量 > 0){
                                        tampFeedbackArr.push({
                                            name: item.名称,
                                            value: item.数量,
                                            percentage:item.百分比,
                                        });
                                    }
                                });
                                this.feedbackEchartsIsEmpty = false;
                                 if(tampFeedbackArr.length === 0){
                                    this.feedbackEchartsIsEmpty = true;
                                }
                                this.answerFeedbackData = tampFeedbackArr.reverse()
                            } else {
                                this.answerFeedbackData = []
                                this.feedbackEchartsIsEmpty = true;
                            }
                            this.loading_11 = 0;
                            break;
                        case 12:
                            if (res.data != null && res.data != "null") {
                                this.qwTopTen = res.data.data;
                            } else {
                                this.qwTopTen = [];
                            }
                            this.loading_10_right = 0;
                            break;
                        case 13:
                            if (res.data != null && res.data != "null") {
                                this.areaList = [...res.data.data];
                                this.$refs.areaComponent.setAreaList(
                                    this.areaList
                                );
                            } else {
                                this.areaList = [];
                            }
                            this.loading_13 = 0;
                            break;
                        case 14:
                            if (res.data != null && res.data != "null") {
                                this.artificialData = res.data;
                                this.artificialData.data = res.data.sourceData && res.data.sourceData.transferDetailDtos ? res.data.sourceData.transferDetailDtos : [];
                                this.artificialData.ratio = res.data.ratio && res.data.ratio !== '0%' ? res.data.ratio : '0.00%'
                                this.artificialData.ratioType = res.data.ratio.charAt(0);
                            } else {
                                this.artificialData = {
                                    totalRate:0,
                                    ratio:'0.00%',
                                    ratioType:"0",
                                    data:[]
                                }
                            }
                            this.loading_14 = 0;
                            break;
                        case 18:
                            let list = [];
                            if (res.data && res.data.data != null && res.data.data != "null" && res.data.data.length > 0) {
                                res.data.data.forEach((item, index) => {
                                    list.push({
                                        '分类': item.typeName,
                                        '数量': item.hits,
                                        '占比':(item.proportion*100).toFixed(2)
                                    })
                                })
                                this.qaConsultechartData.columns = ["分类", "数量"]
                                this.qaConsultechartData.rows = [...list];
                                for (let key in res.data.sourceData.intentQuestionRankMap) {
                                    if (Object.hasOwnProperty.call(res.data.sourceData.intentQuestionRankMap, key)) {
                                        let element = res.data.sourceData.intentQuestionRankMap[key];
                                        if(element.length > 0){
                                            this.qaConsultechartDataTree[key]=element
                                        }
                                        
                                    }
                                }
                                this.qaConsulterchartDataEmpty = false;
                            } else {
                                this.qaConsulterchartDataEmpty = true;
                                this.qaConsultechartData.rows = [];
                                this.qaConsultechartData.columns = ["分类", "数量"];
                                this.qaConsultechartSettings.yAxisName = [];
                            }
                            this.loading_18 = 0;
                            break;
                        case 19:
                            if (res.data != null && res.data != "null") {
                                this.questionsSolutionData = res.data;
                            } else {
                                this.questionsSolutionData = {}
                            }
                            this.loading_19 = 0;
                            break;
                        case 20:
                            this.transWorkorderIsShow = false;
                            this.transWorkerOrderData = {};
                            let isEmptyWorker = 0;
                            if (res.data != null && res.data != "null") {
                                this.transWorkerOrderData = res.data;
                                if(res.data.sourceData && res.data.sourceData.sessionQuestionList && res.data.sourceData.sessionQuestionList.length > 0){
                                    res.data.sourceData.sessionQuestionList.forEach((item,index) => {
                                        if(item.type === 'WORKORDER_SOLVE_SESSION'){
                                            if(item.totalNum === 0){
                                                isEmptyWorker++
                                            }
                                            
                                        } else if(item.type === 'WORKORDER_SOLVE_QUESTION'){
                                            if(item.totalNum === 0){
                                                isEmptyWorker++
                                            }
                                        }
                                    })
                                    if(isEmptyWorker === 2){
                                        this.transWorkorderIsShow = this.transWorkorderIsShows ? this.transWorkorderIsShows : false;
                                    } else {
                                        this.transWorkorderIsShow = true;
                                    }
                                } else {
                                    this.transWorkorderIsShow = this.transWorkorderIsShows;
                                }
                            } else {
                                this.transWorkerOrderData = {}
                                this.transWorkorderIsShow = this.transWorkorderIsShows;
                            }
                            this.loading_20 = 0;
                            break;
                        case 21:
                            this.transImIsShow = false;
                            this.transIMData = {};
                            let isEmptyIM = 0;
                            if (res.data != null && res.data != "null") {
                                this.transIMData = res.data;
                                if(res.data.sourceData &&  res.data.sourceData.sessionQuestionList &&  res.data.sourceData.sessionQuestionList.length > 0){
                                    res.data.sourceData.sessionQuestionList.forEach((item,index) => {
                                        if(item.type === 'IM_SOLVE_SESSION'){
                                            if(item.totalNum === 0){
                                                isEmptyIM++
                                            }
                                            
                                        } else if(item.type === 'IM_SOLVE_QUESTION'){
                                            if(item.totalNum === 0){
                                                isEmptyIM++
                                            }
                                        }
                                    })
                                    if(isEmptyIM === 2){
                                        this.transImIsShow = this.transImIsShows ? this.transImIsShows : false;
                                    } else {
                                        this.transImIsShow = true;
                                    }
                                } else {
                                    this.transImIsShow = this.transImIsShows;
                                }
                            } else {
                                this.transIMData = {}
                                this.transImIsShow = this.transImIsShows;
                            }
                            this.loading_21 = 0;
                            break;
                        case 22:
                            if (res.data != null && res.data != "null") {
                                this.qustionsTransData = res.data;
                            } else {
                                this.qustionsTransData = {}
                            }
                            this.loading_22 = 0;
                            break;
                        case 23:
                            if (res.data != null && res.data != "null") {
                                this.departmentUseData = res.data;
                            } else {
                                this.departmentUseData = {}
                            }
                            this.loading_23 = 0;
                            break;
                        case "0,7":
                            if (!res.data || res.data.data == null) {
                                this.peopleNumberchartDataEmpty = true;
                                this.peoplelargechartData.rows = [];
                                this.peopleNumberchartData.rows = [];
                                this.peopleNumberchartData.columns = [];
                                this.peoplelargechartSettings.yAxisName = [];
                            } else {
                                this.peopleNumberchartDataEmpty = false;
                                this.peopleNumberchartData.columns =
                                    res.data.columns;
                                this.peopleNumberchartData.rows = res.data.data;
                                this.peoplelargechartData.rows = res.data.data;
                                this.peoplelargechartData.columns =
                                    res.data.columns;
                                this.peoplelargechartSettings.yAxisName = [
                                    "客户数",
                                    "",
                                ];
                            }
                            this.loading_m_1 = 0;
                            break;
                        case "1,2":
                            if (!res.data || res.data.data == null) {
                                this.messagelargechartDataEmpty = true;
                                this.messagelargechartData.columns = ["日期", "访问用户", "识别率"];
                                this.messagelargechartData.rows = [{ 日期: "", 访问用户: "", 识别率: "" }];
                                this.messagelargechartSettings.yAxisName = [];
                            } else {
                                this.messagelargechartDataEmpty = false;
                                this.messagelargechartData.columns =
                                    res.data.columns;
                                this.messagelargechartData.rows = res.data.data;
                                this.messagelargechartSettings.yAxisName = [
                                    "客户消息数",
                                    "识别率",
                                ];
                            }
                            this.loading_m_2 = 0;
                            break;
                        case 24:
                            if (res.data != null && res.data != "null") {
                                this.solutionData = res.data;
                                this.solutionData.botSolveRate = res.data.botSolveRate && res.data.botSolveRate !== '0%' ? res.data.botSolveRate : '0.00%'
                                this.solutionData.ratio = res.data.ratio && res.data.ratio !== '0%' ? res.data.ratio: '0.00%';
                                this.solutionData.ratioType = res.data.ratio.charAt(0);
                            } else {
                                this.solutionData = {
                                    ratio: "0.00%",
                                    botSolveRate: '0.00%',
                                    ratioType: "0",
                                    data: []
                                };
                            }
                            this.loading_1_top = 0;
                            break;
                        default:
                            break;
                    }
                } else {
                    switch (indicator) {
                        case 0:
                            this.receptionData = {
                                totalNum:0,
                                validRecordNum: 0,
                                invalidRecordNum: 0,
                                ratio:'0.00%',
                                ratioType:"0",
                                data:[]
                            };
                            this.loading_1_top = 0;
                            // this._totalNum = "empty";
                            break;
                        case 1:
                            this.messageData = {
                                totalNum:0,
                                ratio:'0.00%',
                                ratioType:"0",
                                data:[]
                            }
                            this.loading_2 = 0;
                            // this.messageNumberChartDataEmpty = true;
                            // this.messageNumberChartData.rows = [];
                            break;
                        case 2:
                            this.recognitionRateData = {
                                totalRate:0,
                                ratio:'0.00%',
                                ratioType:"0"
                            }
                            this.loading_3 = 0;
                            // this.messagelargechartDataEmpty = true;
                            // this.messagelargechartData.columns = [];
                            // this.messagelargechartData.rows = [];
                            // this.messagelargechartSettings.yAxisName = [];
                            break;
                        case 3:
                            this.loading_4_top = 0;
                            this.firstResponseTimeData = {
                                respTime:0,
                                ratio:'0.00%',
                                ratioType:"0"
                            }
                            break;
                        case 4:
                            this.loading_4_bottom = 0;
                            this.avResponseTimeData = {
                                respTime:0,
                                ratio:'0.00%',
                                ratioType:"0"
                            }
                            break;
                        case 5:
                            this.loading_5 = 0;
                            this.satisfactionInvestigationData = [];
                            this.satisfTypeEchartsIsEmpty = true;
                            break;
                        case 6:
                            this.loading_6 = 0;
                            this.noSatisfieddReasonData = [];
                            this.noSatisfTypeEchartsIsEmpty = true;
                            break;
                        case 7:
                            this.loading_1_bottom = 0;
                            this.sessionData = {
                                totalNum:0,
                                ratio:'0.00%',
                                ratioType:"0",
                                data:[]
                            }
                            // this._totalSessionNum = "empty";
                            break;
                        case 9:
                            this.loading_9 = 0;
                            this.qwTypeEchartsIsEmpty = true;
                            this.this.questionConsultationDataTree = {};
                            this.this.questionConsultationData = []
                            break;
                        case 10:
                            this.loading_10_left = 0;
                            this.intentTopTenQuestion = {};
                            this.intentTopTen = [];
                            break;
                        case 11:
                            this.loading_11 = 0;
                            this.feedbackEchartsIsEmpty = true;
                            this.answerFeedbackData = [];
                            break;
                        case 12:
                            this.loading_10_right = 0;
                            this.qwTopTen = [];
                            break;
                        case 13:
                            this.loading_13 = 0;
                            this.areaList = [];
                            break;
                        case 14:
                            this.artificialData = {
                                totalRate:0,
                                ratio:'0.00%',
                                ratioType:"0",
                                data:[]
                            }
                            this.loading_14 = 0;
                            break;
                        case 18:
                            this.loading_18 = 0;
                            this.qaConsulterchartDataEmpty = true;
                            this.qaConsultechartData = [];
                            break;
                        case 19:
                            this.loading_19 = 0;
                            this.questionsSolutionData = {};
                            break;
                        case 20:
                            this.loading_20 = 0;
                            this.transWorkerOrderData = {};
                            this.transWorkorderIsShow = this.transWorkorderIsShows;
                            break;
                        case 21:
                            this.loading_21 = 0;
                            this.transIMData = {};
                            this.transImIsShow = this.transImIsShows;
                            break;
                        case 22:
                            this.loading_22 = 0;
                            this.qustionsTransData = {};
                            break;
                        case 23:
                            this.loading_23 = 0;
                            this.departmentUseData = {};
                            break;
                        case "0,7":
                            this.peopleNumberchartDataEmpty = true;
                            this.peoplelargechartData.rows = [];
                            this.peopleNumberchartData.rows = [];
                            this.peopleNumberchartData.columns = [];
                            this.peoplelargechartSettings.yAxisName = [];
                            this.loading_m_1 = 0;
                            break;
                        case "1,2":
                            this.messagelargechartDataEmpty = true;
                            this.messagelargechartData.columns = [];
                            this.messagelargechartData.rows = [];
                            this.messagelargechartSettings.yAxisName = [];
                            this.loading_m_2 = 0;
                            break;
                        case 24:
                            this.solutionData = {
                                botSolveRate: '0.00%',
                                ratio: "0.00%",
                                ratioType:"0",
                                data:[]
                            };
                            this.loading_1_top = 0;
                            break;
                        default:
                            break;
                    }
                }
            });
        },
        reload13() {
            this.getStatisticsDataNew(
                this.newStartTime,
                this.newEndTime,
                13,
                "indicator"
            );
        },
        // 全部加载数据
        getDataCell(startTime, endTime) {
            this.getStatisticsDataNew(startTime, endTime, 1, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 24, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 2, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 3, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 4, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 7, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 0, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 14, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 20, "indicator");
            this.getStatisticsDataNew(startTime, endTime, 21, "indicator");
            setTimeout(() => {
                this.getStatisticsDataNew(startTime, endTime, 19, "indicator");
                
                this.getStatisticsDataNew(startTime, endTime, 22, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 23, "indicator");
                this.getStatisticsDataNew(
                    startTime,
                    endTime,
                    "0,7",
                    "indicators"
                );
                this.getStatisticsDataNew(
                    startTime,
                    endTime,
                    "1,2",
                    "indicators"
                );
            }, 300);
            setTimeout(() => {
                this.getStatisticsDataNew(startTime, endTime, 5, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 6, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 9, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 10, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 12, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 11, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 13, "indicator");
                this.getStatisticsDataNew(startTime, endTime, 18, "indicator");

            }, 800);
        },
        // 保存设置问题类型
        saveSetType(list) {
            this.FetchPost(
                this.requestUrl.statistics.configQuestionType +
                this.formatterActiveScopeValue(this.activeScopeValue) +
                "?scopeType=" +
                this.activeScopeType,
                {
                    questionTypeConfigs: list,
                }
            ).then((res) => {
                this.getStatisticsDataNew(
                    this.newStartTime,
                    this.newEndTime,
                    9,
                    "indicator"
                );
                this.setTypeDialogVisible = false;
            });
        },
        // 取消保存设置问题类型
        cancelSaveSetType() {
            this.setTypeDialogVisible = false;
        },
        todayData() {
            this.currentDate = "today";
            let endTime = new Date().getTime();
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let todayStartTime = yy + "-" + mm + "-" + dd + " " + "0:0:0";
            let todayStartTimeDate = new Date(
                Date.parse(todayStartTime.replace(/-/g, "/"))
            );
            let startTime = todayStartTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.getDataCell(startTime, endTime);
        },
        yesterdayData() {
            this.currentDate = "yesterday";
            var time = new Date().getTime() - 24 * 60 * 60 * 1000;
            var yesday = new Date(time); // 获取的是前一天日期
            yesday =
                yesday.getFullYear() +
                "-" +
                (yesday.getMonth() > 9
                    ? yesday.getMonth() + 1
                    : yesday.getMonth() + 1) +
                "-" +
                yesday.getDate();

            let yesterdayStartTime = yesday + " " + "0:0:0";
            let yesterdayEndTime = yesday + " " + "23:59:59";
            let yesterdayStartTimeDate = new Date(
                Date.parse(yesterdayStartTime.replace(/-/g, "/"))
            );
            let yesterdayEndTimeDate = new Date(
                Date.parse(yesterdayEndTime.replace(/-/g, "/"))
            );
            let startTime = yesterdayStartTimeDate.getTime();
            let endTime = yesterdayEndTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.getDataCell(startTime, endTime);
        },
        lastWeekData() {
            this.currentDate = "lastWeek";
            let endTime = new Date().getTime();
            let lastWeekDate = this.getDay(-6);
            let lastWeekTime = lastWeekDate + " " + "0:0:0";
            let startTimeDate = new Date(
                Date.parse(lastWeekTime.replace(/-/g, "/"))
            );
            let startTime = startTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.getDataCell(startTime, endTime);
        },
        lastMonthData() {
            this.currentDate = "lastMonth";
            let endTime = new Date().getTime();
            let lastWeekDate = this.getDay(-29);
            let lastWeekTime = lastWeekDate + " " + "0:0:0";
            let startTimeDate = new Date(
                Date.parse(lastWeekTime.replace(/-/g, "/"))
            );
            let startTime = startTimeDate.getTime();
            this.newStartTime = startTime;
            this.newEndTime = endTime;
            this.getDataCell(startTime, endTime);
        },
        datePickerChange() {
            this.isInit = false;
            if (this.fastChooseDatePickerType === 'monthrange') {
                if (this.$refs.fastChooseDatePicker) {
                    this.$refs.fastChooseDatePicker.handlerMonthClose();
                }
            }
            if (this.dateSeleteData.value) {
                this.currentDate = "diy";
                if (this.dateSeleteData.value !== '' && this.dateSeleteData.value.length === 2) {
                    if (typeof (this.dateSeleteData.value[0]) !== 'number') {
                        this.newStartTime = this.dateSeleteData.value[0].getTime();
                    }
                    if (typeof (this.dateSeleteData.value[1]) !== 'number') {
                        this.newEndTime = this.dateSeleteData.value[1].getTime();
                    }
                }

                this.getDataCell(this.newStartTime, this.newEndTime);
            } else {
                this.yesterdayData();
            }
        },
        changeGetData() {
            switch (true) {
                case this.currentDate === "today":
                    this.todayData();
                    break;
                case this.currentDate === "yesterday":
                    this.yesterdayData();
                    break;
                case this.currentDate === "lastWeek":
                    this.lastWeekData();
                    break;
                case this.currentDate === "lastMonth":
                    this.lastMonthData();
                    break;
                case this.currentDate === "diy":
                    this.datePickerChange();
                    break;
            }
        },
        getDay(day) {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds);
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        init() {
            const self = this; //因为箭头函数会改变this指向，指向windows。所以先把this保存
        },
    },
    mounted() {
        let date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24);
        let startTime = new Date(date.setHours(0, 0, 0, 0))
        let start = startTime.setTime(startTime.getTime());

        let endTime = new Date(date.setHours(23, 59, 59, 999))
        let end = endTime.getTime();

        this.newStartTime = start;
        this.newEndTime = end;
        this.dateSeleteData.value = [start, end]
        this.getStatisticsScopes();
        this.init();
        this.$nextTick(() => {
            this.showMap = true;
        });
    },
    watch: {
        '$parent.testDrawerVisibleP'(n) {
            this.histogramNum++;
        }
    }
};
</script>
<style lang="less" scoped>
#statistics {
    overflow-x: auto;
    overflow-y: hidden;

    .top-header-right {
        justify-content: flex-start;
    }

    .content-main {
        min-width: 1140px;

        .ask-statistics-row {}
    }
}

@import "./../../assets/less/statistics/statisticsUp.less";
</style>