<template>
    <div id="qustionstrans">
        <div class="asksta-simple-title">
            <div class="ast-left">
                <span class="ast-left-name">{{$t('statisticsUp.questionTransTitle')}}</span>
                <span class="ast-left-icon">
                    <el-tooltip class="item" effect="dark" :content="$t('statisticsUp.questionTransTip')" placement="top-start">
                        <em class="el-icon-warning-outline"></em>
                    </el-tooltip>
                </span>
            </div>
        </div>
        <div class="asksta-receivenum-char" v-show="!isNoData">
            <div style="height: 530px" id="asksta-qustionstrans-char"></div>
        </div>
        <div style="height: 530px" v-if="isNoData">
            <no-data-echarts ></no-data-echarts>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
// import { color } from 'echarts/lib/export';
import NoDataEcharts from './noDataEcharts.vue'
export default {
    data() {
        return {
            myChart: null,
            isNoData:false
        }
    },
    components:{NoDataEcharts},
    props:{
        qustionsTransData:{
            type:Object,
            default(){
                return {}
            }
        },
        loading:{
            type:Boolean,
            default:true
        }
    },
    watch:{
        qustionsTransData:{
            handler(n){
                if(!this.loading){
                    this.isNoData = false;
                    this.iniEchart(n);
                }
            },
            deep:true
        },
    },
    methods: {
        iniEchart(data) {
            let yData = [],xData = [];
            if(data.sourceData && data.sourceData.intentTransferOrderRank && data.sourceData.intentTransferOrderRank.length > 0){
                data.sourceData.intentTransferOrderRank.forEach((item,index) => {
                    yData.push(item.intentName);
                    xData.push(item.num);
                })
            }
            // 模拟数据
            // yData = ['1','2','3','4','5','6','7','8','9','343','435','675','43','345','78','89','65','32','98','11','13','34','1','2','3','4','5','6','7','8','9','343','435','675','43','345','78','89','65','32','98','11','13','34'];
            // xData = [1,2,3,4,5,6,7,8,9,343,435,675,43,345,78,89,65,32,98,11,13,14,1,2,3,4,5,6,7,8,9,343,435,675,43,345,78,89,65,32,98,11,13,14]
            var chartDom = document.getElementById('asksta-qustionstrans-char');
            if (this.myChart != null) {
                this.myChart.clear();
            }
            this.myChart = echarts.init(chartDom);
            let option = {};
            if(yData.length === 0 || xData.length === 0 ){
                this.isNoData = true;
                // option = {
                //     title: {
                //         text: '暂无数据',
                //         x: 'center',
                //         y: 'center',
                //         textStyle: {
                //             fontSize: 16,
                //             fontWeight: 'normal',
                //             color:'#A9B3C6'
                //         }
                //     }
                // }
            } else {
                 this.isNoData = false;
                option = {
                    tooltip: {
                        axisPointer: {
                            // Use axis to trigger tooltip
                            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                        },
                        textStyle:{
                            align:'left'
                        }
                    },
                    grid: {
                        show: false,
                        top: '40',
                        right: '5%',
                        bottom: '40',
                        left: '15%'
                    },
                    xAxis: {
                        type: 'value',
                        splitLine: false,
                        axisLine: {
                            show: false //隐藏y轴
                        },
                        axisTick: {
                            show: false // 不显示坐标轴刻度线
                        },
                        axisLabel: {
                            show: false,
                            inside: false,
                            
                        },
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'category',
                        data: yData,
                        splitLine: false,
                        // max: 250,
                        // interval: 50,
                        axisLine: {
                            show: true, //隐藏y轴
                            lineStyle:{
                                type:'dashed',
                                color:"#616161"
                            },
                        },
                        axisTick: {
                            show: false // 不显示坐标轴刻度线
                        },
                        axisLabel: {
                            // formatter: '{value}',
                            color: '#000000',
                            fontSize:14,
                            formatter: function (value) {
                                if (value.length > 10) {
                                return `${value.slice(0, 10)}...`;
                                }
                                return value;
                            }

                        }
                    },
                    series: [
                        {
                            name: this.$t('statisticsUp.questionTransTitle'),
                            type: 'bar',
                            barMaxWidth: '20px',
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                    { offset: 0, color: '#09DDD5' },
                                    { offset: 0.5, color: '#458FFF' },
                                    { offset: 1, color: '#188df0' }
                                ]),
                                barBorderRadius: [50, 50, 50, 50]
                            },
                            stack: 'total',
                            label: {
                                show: true,
                                color: "#fff"
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            data: xData
                        }
                    ],
                    dataZoom: [{
                        type: 'slider',
                        show: true,
                        // 设置组件控制的y轴
                        yAxisIndex: 0,
                        left: 0,    
                        // top: 60,
                        // 数据窗口范围的起始百分比。范围是：0 ~ 100。表示 0% ~ 100%
                        // 也可以用 startValue设置起始值
                        start: 0,
                        end: 36,
                        width: 8,
                        // height: 450,
                        // 组件的背景颜色
                        // left: 600, //左边的距离
                        // right: 8,//右边的距离
                        borderRadius: 20,
                        borderColor: "#BFCEEC",
                        fillerColor: '#366aff',//滑动块的颜色
                        backgroundColor: '#BFCEEC',//两边未选中的滑动条区域的颜色
                        // 是否显示detail，即拖拽时候显示详细数值信息
                        showDetail: false,
                        // 控制手柄的尺寸
                        handleSize: 16,
                        // 是否在 dataZoom-silder 组件中显示数据阴影。数据阴影可以简单地反应数据走势。
                        showDataShadow: false,
                        },
                        {
                        type: 'inside',
                        yAxisIndex: [0],
                        start: 1,
                        // end: 36,
                        // 不按任何功能键，鼠标滚轮能触发缩放
                        zoomOnMouseWheel: false,
                        // 不按任何功能键，鼠标移动能触发数据窗口平移
                        moveOnMouseWheel: true
                    }],
                };
            }
            option && this.myChart.setOption(option);

            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        }
    },
}
</script>
<style lang="less" scoped>
#qustionstrans {
    height: 614px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 0px rgba(29, 55, 129, 0.07);
    border-radius: 5px;
    margin-top: 14px;
    overflow: hidden;
    .asksta-simple-title {
        display: flex;
        justify-content: space-between;
        height: 20px;
        line-height: 20px;
        padding: 24px;

        .ast-left {
            width: 200px;
            flex: none;
            text-align: left;
            display: flex;
            justify-content: flex-start;

            .ast-left-name {
                margin-right: 4px;
                font-size: 16px;
                font-weight: 400;
                color: #000000;
            }
        }

        .ast-right {
            flex: auto;

            .ast-right-bottom {
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .ast-right-top {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }

            .ast-right-cell-bg-lb {
                background-color: rgba(54, 106, 255, 0.2);
                padding: 0 6px;
                border-radius: 10px;
            }

            .ast-right-cell-bg-lp {
                background-color: rgba(255, 114, 114, 0.2);
                padding: 0 6px;
                border-radius: 10px;
            }

            .ast-right-cell {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 16px;
                font-size: 13px;
                line-height: 20px;

                .arc-icon {
                    margin-right: 4px;

                    i {
                        font-size: 12px;
                    }

                    .color-b-1 {
                        color: #1A53F5;
                    }

                    .color-b-08 {
                        color: #366AFF;
                    }

                    .color-b-04 {
                        color: #458FFF;
                    }

                    .color-b-02 {
                        color: #89B8FF;
                    }

                    .color-b-01 {
                        color: #BBD6FF;
                    }

                    .color-p-1 {
                        color: #F74E4E;
                    }

                    .color-p-08 {
                        color: #FF7272;
                    }

                    .color-p-04 {
                        color: #FF8F97;
                    }

                    .color-p-02 {
                        color: #FFBABF;
                    }

                    .color-p-01 {
                        color: #FFDDDF;
                    }

                    .color-gray {
                        color: #A9B3C6;
                    }
                }

                .arc-name {
                    cursor: pointer;
                    color: #000000;
                    font-weight: 600;
                }

                .arc-num {
                    color: #000000;
                    font-weight: 600;
                    margin-right: 4px;
                }

                .arc-per-pre {
                    color: #A9B3C6;
                }

                .arc-type {
                    margin-left: 6px;
                    margin-right: 4px;

                }

                .arc-type-num-box {

                    height: 16px;
                    line-height: 16px;
                    background: #F5F7FB;
                    border-radius: 8px;
                    padding: 0 3px;

                    .arc-type-icon-up {
                        color: #FE5965;

                        i {
                            font-size: 12px;
                        }

                    }

                    .arc-type-icon-down {
                        color: #09DDD5;

                        i {
                            font-size: 12px;
                        }

                    }
                }
            }
        }
    }
    .asksta-receivenum-char{
        padding-left: 50px;
    }
}
</style>