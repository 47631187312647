<template>
    <div class="satisfactionInvestigation">
        <div class="title with-handle-title">
            <span>
                {{$t('statisticsUp.satisfactionTitle')}}
                <el-tooltip class="item" effect="dark" :content="$t('statisticsUp.satisfactionTip')" placement="top-start">
                    <em class="el-icon-warning-outline"></em>
                </el-tooltip>
            </span>
        </div>
        <div class="legend-box" v-show="isEmipty">
            <no-data-echarts></no-data-echarts>
        </div>
        <div v-loading="loading" v-show="!isEmipty" id="satisfTypeEcharts1"></div>

    </div>
</template>

<script>
import NoDataEcharts from './noDataEcharts.vue'
export default {
    props:{
        data:{
            type:Array,
            default(){
                return []
            }
        },
        loading:{
            type:Boolean,
            default:true
        },
        isEmipty:{
            type:Boolean,
            default:false
        }
    },
    components:{
        NoDataEcharts
    },
    watch:{
        data:{
            handler(n){
                if(!this.loading){
                    if(this.isEmipty)return;
                    setTimeout(() => {
                        this.iniEchart(n);
                    },500)
                }
            },
            deep:true
        },
    },
    methods:{
        // 满意度调查
        iniEchart(data) {
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(
                document.getElementById("satisfTypeEcharts1")
            );
            // 绘制图表
            myChart.setOption({
                title: {
                    text: "",
                    subtext: "",
                    left: "center",
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)",
                },
                legend: {
                    orient: 'vertical',  // 图例竖向排列
                    icon:"path://M512 0C229.376 0 0 228.864 0 512s228.864 512 512 512 512-228.864 512-512S795.136 0 512 0z m0 765.952c-140.288 0-253.952-113.664-253.952-253.952S371.712 258.048 512 258.048s253.952 113.664 253.952 253.952c0.512 140.288-113.664 253.952-253.952 253.952z",
                    align: 'left',
                    x:'right',      //可设定图例在左、右、居中
                    y:'center',     //可设定图例在上、下、居中
                    padding:[0,50,0,50],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
                },
                color: ['#FF9555','#458FFF'],
                series: [
                    {
                        name: this.$t('statisticsUp.satisfactionTitle'),
                        type: "pie",
                        center: ["40%", "45%"],
                        radius: ["35%", "65%"],
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: "#fff",
                                borderWidth: 15,
                            },
                        data: data,
                        labelLine:{  
                            normal:{  
                                length:30,   	// 指示线长度
                            },
                        },
                        label: {
                            normal: {
                                textStyle: {
                                    color: '#616161',	// 提示文字颜色
                                    fontSize: 13		// 提示文字大小
                                } ,
                                formatter: function (params) {
                                    let percentage = (params.data.percentage*100).toFixed(2)
                                    return `${params.data.name}\n${percentage}%`
                                }
                            },
                        },
                    },
                ],
            });
        },
    }
}
</script>

<style lang="less" scoped>
.satisfactionInvestigation{
    height: 100%;
    .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        span{
            flex: 0.9 0.9 0;
        }

        .help{
            flex: 0.1 0.1 0;
        }

    }
    #satisfTypeEcharts1{
        height: 280px;
        margin: 0 auto;
    }
    .legend-box{
        height: 100%;
    }
}

</style>