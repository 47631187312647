<template>
    <div id="question-consultation-rank">
        <div class="asksta-simple-title">
            <div class="ast-left" :style="$i18n.locale === 'en' ? 'width:320px' : ''">
                <span class="ast-left-name">{{$t('statisticsUp.questionConsultationRank')}}</span>
                <el-tooltip class="item" effect="dark" :content="$t('statisticsUp.questionConsultationRankTip')" placement="top-start">
                    <em class="el-icon-warning-outline"></em>
                </el-tooltip>
            </div>
            <el-button icon="iconfont guoran-a-24-06" @click="openQaConsult">{{$t('statisticsUp.setQuestionRank')}}</el-button>
        </div>
        <div style="height: 350px" v-if="isNoData">
            <no-data-echarts></no-data-echarts>
        </div>
        <div class="asksta-receivenum-char">
            <div style="height: 350px" id="question-consultation-rank-char"></div>
        </div>
        <intentQuestionDrewer 
            :visible.sync="visible" 
            :tableDataTree="activeDataTree"
            :drewerType.sync="drewerType"
            title="问题咨询详情">
        </intentQuestionDrewer>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import NoDataEcharts from './noDataEcharts.vue';
import intentQuestionDrewer from './intentQuestionDrewer.vue'
import {v4 as uuidv4} from "uuid";
export default {
    data() {
        return {
            myChart: null,
            isNoData:false,
            activeDataTree:[],
            visible:false,
            drewerType:''
        }
    },
    components:{NoDataEcharts,intentQuestionDrewer},
    props:{
        data:{
            type:Object,
            default(){
                return {}
            }
        },
        loading:{
            type:Boolean,
            default:true
        },
        allDataTree:{
            type:Object,
            default(){
                return {}
            }
        }
    },
    watch:{
        data:{
            handler(n){
                if(!this.loading){
                    this.isNoData = false
                    this.iniEchart(n);
                }
            },
            deep:true
        },
    },
    methods: {
        iniEchart(data) {
            let yData = [],xData = [];
            if(data.rows){
                data.rows.forEach((item,index) => {
                    // if(item.数量 > 0){
                        xData.push(item['数量']);
                    // }
                    yData.push(item['分类']);
                })
            }
            var chartDom = document.getElementById('question-consultation-rank-char');
            if (this.myChart != null) {
                this.myChart.clear();
            }
            this.myChart = echarts.init(chartDom);
            let option = {};
            if(yData.length === 0 || xData.length === 0 ){
                this.isNoData = true
                // option = {
                //     title: {
                //         text: '暂无数据',
                //         x: 'center',
                //         y: 'center',
                //         textStyle: {
                //             fontSize: 16,
                //             fontWeight: 'normal',
                //             color:'#A9B3C6'
                //         }
                //     }
                // }
            } else {
                this.isNoData = false
                option = {
                   tooltip: {
                       show: true,
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        },
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        },
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '5%',
                        containLabel: true
                    },
                   
                    xAxis: {
                        data: yData,
                        type: 'category',
                        splitLine: false,
                        axisLine: {
                            show: false //隐藏y轴
                        },
                        axisTick: {
                            show: true // 不显示坐标轴刻度线
                        },
                        axisLabel: {
                            show: true,
                            inside: false
                        },
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    yAxis: {
                        type: 'value',
                        name: '',
                        min: 0,
                        axisLine: {
                            show: false, //隐藏y轴
                        },
                        axisTick: {
                            show: false // 不显示坐标轴刻度线
                        },
                        axisLabel: {
                            formatter: '{value}',
                            color: '#A9B3C6',
                        }
                    },
                    series: [
                        {
                            show: false,
                            name: this.$t('statisticsUp.totalNumberOfReceptions'),
                            type: 'bar',
                            barMaxWidth: '20px',
                            tooltip: {
                                valueFormatter: function (value) {
                                    return value;
                                }
                            },
                            data: xData,
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: '#09DDD5' },
                                    { offset: 0.5, color: '#458FFF' },
                                    { offset: 1, color: '#188df0' }
                                ]),
                                barBorderRadius: [50, 50, 50, 50]
                            },
                        }
                    ]
                };
            }
            option && this.myChart.setOption(option);
            this.myChart.on("click", this.eConsole);
            window.addEventListener("resize", () => {
                this.myChart.resize();
            });
        },
        openQaConsult(){
            this.$emit('openQaConsult')
        },
         //增长监听事件
        eConsole(param) {
            if (typeof param.seriesIndex != 'undefined') {
                // 获取总共给分隔的扇形数
                let peiLenght= this.data.rows.length;
                this.data.rows.forEach((item,index) => {
                    this.everyClick(param)
                })
            }
        },
        everyClick(params){  
            let percentage = ''
            this.data.rows.forEach((item,index) => {
                if(item.分类 === params.name){
                    percentage = item.占比

                }
            })
            this.activeDataTree = [{
                name:params.name,
                percentage:percentage,
                value:params.value,
                id:uuidv4().toString().replaceAll("-", ""),
                children:[]
            }]
            for (let key in this.allDataTree) {
                if (Object.hasOwnProperty.call(this.allDataTree, key)) {
                    let element = this.allDataTree[key];
                    if(key == params.name){
                        element.forEach((item,index) => {
                            this.activeDataTree[0].children.push({
                                name:item.intentName,
                                percentage:(item.proportion*100).toFixed(2),
                                value:item.hits,
                                id:uuidv4().toString().replaceAll("-", "")
                            });
                        })
                    }
                }
            }
            this.drewerType = 'questionDetail'
            this.visible = true;
        }
    },
}
</script>
<style lang="less" scoped>
#question-consultation-rank {
    height: 420px;
    border-radius: 5px;
    margin-top: 15px;
    overflow: hidden;
    .asksta-simple-title {
        display: flex;
        justify-content: space-between;
        height: 20px;
        line-height: 20px;
        padding: 24px;
        width: calc(100% - 48px);
        .ast-left {
            width: 200px;
            flex: none;
            text-align: left;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .ast-left-name {
                margin-right: 4px;
                font-size: 16px;
                font-weight: 400;
                color: #000000;
            }
        }
        /deep/ .el-button{
            //width: 116px;
            height: 29px;
            background: #FFFFFF;
            border: 1px solid #E0E6F7;
            border-radius: 5px;
            padding: 0 6px;
            font-size: 14px;
            color: #616161;
            .iconfont{
                font-size: 14px;
                color: #616161;
                margin-right: 4px;
            }
        }
    }
}
</style>